<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
  <ng-container *ngIf="viewModel.eventOfferTypes.length > 0">
    <mat-checkbox *ngIf="canEdit()" (change)="toggleSelectAll($event.checked)" [checked]="isAllSelected()"
      [indeterminate]="isSomeSelectedOnPage() && !isAllSelected()">Select All</mat-checkbox>

    <div class="grid">
      <mat-card appearance="outlined"
        *ngFor="let offerType of viewModel?.eventOfferTypes; index as i; trackBy: trackByFn">
        <mat-card-content>

          <span class="rank" *ngIf="offerType?.Detail?.Rank">Rank : {{ offerType?.Detail?.Rank }}</span>

          <div class="offer-status">
            <span [matMenuTriggerFor]="canEdit() ? menu : null" class="ellipsis-3-line {{ getStatusClass(offerType) }}">
              {{ getStatusText(offerType) }}
              <mat-icon *ngIf="canEdit()" class="icon">arrow_drop_down</mat-icon>
            </span>
          </div>

          <mat-menu #menu="matMenu" class="status-menu">
            <button type="button" mat-menu-item class="statusAPPROVED"
              [disabled]="offerType?.Detail?.Status === 'APPROVED'" (click)="updateStatus(Status.APPROVED, offerType)">
              APPROVED
            </button>
            <button type="button" mat-menu-item class="statusPENDING"
              [disabled]="offerType?.Detail?.Status === 'PENDING'" (click)="updateStatus(Status.PENDING, offerType)">
              PENDING
            </button>
            <button type="button" mat-menu-item class="statusSUPPRESSED"
              [disabled]="offerType?.Detail?.Status === 'SUPPRESSED'" (click)="updateStatus(Status.SUPPRESSED, offerType)">
              SUPPRESSED
            </button>
          </mat-menu>

          <div class="grid-col-span-2 offer-name" title="{{ offerType?.Detail?.Name }}" fxLayout="row wrap"
            fxLayoutAlign="start start" fxLayoutGap="15px">
            <mat-checkbox *ngIf="!readOnlyMode" (click)="$event.stopPropagation()" fxFlex="30px" class="card-checkbox"
              (change)="toggleSelect($event.checked, offerType)" [checked]="selection.isSelected(offerType)">
            </mat-checkbox>
          </div>

          <div fxLayoutAlign="center center" class="offer-image">
            <img mat-card-md-image
              [src]="offerType.FacingAsset ? utilityService.getOfferPreviewUrl(offerType.FacingAsset, PreviewMode.Medium) : './assets/images/default-image.png'"
              alt="Offer image" (error)="onImageError($event)">
          </div>

          <div class="offer-content" fxLayout="column">
            <div *ngIf="offerType?.Variants?.length && offerType.Variants.length > 1" class="offer-bodycopy">
              <mat-form-field class="versions" appearance="outline">
                <mat-select [(value)]="offerType.Detail.Id" placeholder="Select Variant"
                  (selectionChange)="onVariantChange($event.value, offerType)">
                  <mat-option *ngFor="let variant of offerType?.Variants" [value]="variant?.Id">
                    {{variant?.OfferVariantName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="offer-headline">
              <span [innerHtml]="offerType?.PricingPreviewHtml"></span>
            </div>
          </div>

        

          <div class="card-footer grid-col-span-2" fxLayout="row wrap" fxLayoutGap="15px">
            <span title="{{ offerType?.OfferTagNames }}" class="card-footer-text w-100">
              Tags : {{ offerType?.OfferTagNames }}</span>
            <span title="{{ offerType?.VersionNames }}" class="card-footer-text version-codes w-100">
              Versions : {{ offerType?.VersionNames }}</span>

            <div class="card-footer-text">
              <span class="footer-text">{{ offerType?.EventOfferType == 'OFFERGROUP'? 'OFFER GROUP':
                offerType?.EventOfferType }}<span
                  *ngIf="offerType?.EventOfferType == 'OFFERGROUP'">({{offerType?.OffersCount}})</span></span>
              <span class="footer-text display">Client Key: <span
                  title="{{ offerType?.Detail?.ClientKey }}">{{offerType?.Detail?.ClientKey }}</span></span>
            </div>

            <div fxLayOut="row" class="card-footer-btns">
              <button
                *ngIf="offerType?.VariantsCount && offerType.VariantsCount > 1 && offerType?.EventOfferType === 'OFFER'"
                mat-button type="button" color="accent" [routerLink]="[offerType?.Detail?.Id, 'childversions']">
                Variants({{offerType?.VariantsCount}})</button>
              <button
                *ngIf="offerType?.VariantsCount && offerType.VariantsCount > 1 && offerType?.EventOfferType === 'PROMO'"
                mat-button type="button" color="accent"
                [routerLink]="[offerType?.Detail?.Id, 'promo', 'childversions']">
                Variants({{offerType?.VariantsCount}})</button>
              <button *ngIf="offerType?.EventOfferType === 'OFFER'" class="offer-button" type="button" mat-button
                color="accent" [routerLink]="[offerType?.Detail?.Id, 'details']"
                [queryParams]="{ offer_filters:'true' }">
                <span *ngIf="canEdit()">{{canEdit()?'Edit':'View Details'}}</span>
              </button>
              <button *ngIf="offerType?.EventOfferType === 'PROMO'" class="offer-button" type="button" mat-button
                color="accent" [routerLink]="[offerType?.Detail?.Id, 'promos', 'promodetails']"
                [queryParams]="{ offer_filters:'true' }">
                <span *ngIf="canEdit()">{{canEdit()?'Edit':'View Details'}}</span>
              </button>
              <button *ngIf="offerType?.EventOfferType === 'OFFERGROUP'" class="offer-button" type="button" mat-button
                color="accent" [routerLink]="[offerType?.Detail?.Id, 'offergroups', 'offergrpdetails']"
                [queryParams]="{ offer_filters:'true' }">
                <span *ngIf="canEdit()">{{canEdit()?'Edit':'View Details'}}</span>
              </button>
              <div class="comments pl-18">
                <ng-container
                  *ngIf="offerType?.CommentsCount !== undefined && offerType?.CommentsCount! > 0; else noComments">
                  <div id="{{offerType?.DomainId}}" (click)="openDialog($event, offerType)" class="comments-dialog">
                    <i class="fa fa-comment font-12" aria-hidden="true"></i>
                    <span class="comments-count">{{offerType?.CommentsCount}}</span>
                  </div>
                </ng-container>
                <ng-template #noComments>
                  <span id="{{offerType?.DomainId}}" (click)="openDialog($event, offerType)" class="comments-dialog">
                    <i class="fa fa-comment-o font-12" aria-hidden="true"></i>
                  </span>
                </ng-template>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <mat-paginator [length]="viewModel?.totalRecords" [pageSize]="viewModel?.page?.pageSize"
      [pageSizeOptions]="[5, 10, 20, 50, 100, 500]" (page)="onPage($event)" showFirstLastButtons
      [pageIndex]="viewModel?.page?.pageIndex">
    </mat-paginator>
  </ng-container>

  <ng-container *ngIf="viewModel?.eventOfferTypes?.length === 0">
    <table>
      <tr>
        <td class="no-offers">
          <h2>No offers Found</h2>
        </td>
      </tr>
    </table>
  </ng-container>
</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>