import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MontageHistoryPreviewComponent } from '../montage-history-preview/montage-history-preview.component';
import { MontageBuilderService } from '../../services/montage-builder.service';

@Component({
  selector: 'app-montage-history-dialog',
  templateUrl: './montage-history-dialog.component.html',
  styleUrls: ['./montage-history-dialog.component.scss']
})
export class MontageHistoryDialogComponent implements OnInit {
  constructor(public matDialogRef: MatDialogRef<MontageHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private dialog: MatDialog,private montageBuilderService: MontageBuilderService) { }
  ngOnInit(): void {
  }
  ShowVersionHistory() {
    const dialogRef =  this.dialog.open(MontageHistoryPreviewComponent, {
      width: '40%',
      height: '600px',
      data: this.data,
      disableClose: true,
    });
    
    dialogRef.afterClosed().subscribe((data)=>{
      if(data=='close'){
        this.matDialogRef.close('close');
      } else {
        this.matDialogRef.close('apply');
      }
    });

   
  }
  CreateNewVersion() {
    this.matDialogRef.close('close');
  }
}
