import { AfterViewInit, Component, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger, PreviewMode, UtilityService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { OfferPromo, PromoDomain, OfferPromoService, OfferService, Offer, OfferDomain, OfferGroupDomain, } from '../..';
import { SelectionModel } from '@angular/cdk/collections';
import { Status } from '../../models/offer-promo-status';
import { AuthorizeControlService } from 'pr1-ui-components';
import { ActivatedRoute, Router } from '@angular/router';
import { EventWorkflowService } from '../../services/event-workflow.service';
import { MatTableDataSource } from '@angular/material/table';

const log = new Logger('OfferListTableComponent');

@Component({
  selector: 'app-version-offer-table',
  templateUrl: './version-offer-table.component.html',
  styleUrls: ['./version-offer-table.component.scss'],
})
export class VersionOfferTableComponent<TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>, T extends Offer, TOfferDomain extends OfferDomain<Offer>> implements OnInit, AfterViewInit {
  Status: typeof Status = Status;
  PreviewMode = PreviewMode;
  viewModel$ = combineLatest([
    this.offerService.getofferChildVersions$,
    this.offerService.isLoading$
  ]).pipe(
    map(([offers, isLoading]) => {
      this.offerVariantList = new MatTableDataSource(offers);
      return { offers, isLoading };
    })
  );

  offerVariantList: MatTableDataSource<OfferGroupDomain<Offer>> = new MatTableDataSource();
  selection = new SelectionModel<OfferGroupDomain<Offer>>(true, [], true, (o1, o2) => o1.DomainId === o2.DomainId);
  readOnlyMode = true;
  private subscriptions: Subscription[] = [];
  displayedColumns = ['select', 'Image', 'Name', 'Version', 'Headline', 'BodyCopy', 'EventType', 'VersionCodes', 'Status', 'Rank', 'Actions'];

  constructor(
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    public utilityService: UtilityService,
    private mediaObserver: MediaObserver,
    private authorizationService: AuthorizeControlService,
    public offerService: OfferService<T, TOfferDomain>,
    private router: Router,
    private route: ActivatedRoute,
    private ewService: EventWorkflowService
  ) { }

  ngOnInit(): void {
    log.debug('init');
    this.detectViewportSizeChange();

    this.readOnlyMode = this.offerpromoService.getOfferDetailsReadOnlyValue();
    this.subscriptions.push(this.offerpromoService.offerDetailsReadOnlyMode$.subscribe((res) => {
      this.readOnlyMode = res;
    }));
  }

  ngAfterViewInit() {
    if (this.ewService.readOnlyMode != null) {
      this.readOnlyMode = this.ewService.readOnlyMode;
      return;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  detectViewportSizeChange() {
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.subscriptions.push(this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        )
      ).subscribe((change) => {
        if (change.some((x) => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'Name', 'Actions'];
        } else if (change.some((x) => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'Name', 'Headline', 'Actions'];
        } else {
          this.displayedColumns = ['select', 'Image', 'Name', 'Version', 'Headline', 'BodyCopy', 'EventType', 'VersionCodes', 'Status', 'Rank', 'Actions'];
        }
      }));
  }

  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = './assets/images/default-image.png'; // Path to your default image
  }

  onEdit(id: string) {
    this.router.navigate([`../../${id}/details`], { relativeTo: this.route });
  }

  onSort(sortState: Sort): void {
    this.offerService.sort(sortState);
  }

  clearSelection() {
    this.selection.clear();
  }

  updateStatus(status: Status, row: OfferGroupDomain<Offer>) {
    let selectedRecordsDetails: OfferGroupDomain<Offer>[] = [JSON.parse(JSON.stringify(row))];
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateRecordsStatus(selectedRecordsDetails: Array<OfferGroupDomain<Offer>>, status: Status) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      selectedRecordsDetails = selectedRecordsDetails.map((record: OfferGroupDomain<Offer>) => {
        record.Detail.Status = status as unknown as string;
        return record;
      });
      this.offerpromoService.updateStatus(selectedRecordsDetails).subscribe({
        next: () => {
          this.offerpromoService.reload();
          this.clearSelection();
          this.offerpromoService.updateRecordStatus(new Date());
        },
        error: (err) => {
          log.error('Failed to update status', err);
        }
      });
    }
  }

  getSelectedSectionRecords() {
    return this.selection.selected;
  }

  isAllSelected(): boolean {
    return this.offerVariantList.data.every(offer => this.selection.isSelected(offer));
  }

  toggleSelectAll(checked: boolean): void {
    if (checked) {
      this.offerVariantList.data.forEach(offer => {
        if (!this.selection.isSelected(offer)) {
          this.selection.select(offer);
        }
      });
    } else {
      this.offerVariantList.data.forEach(offer => {
        this.selection.deselect(offer);
      });
    }
  }

  isSomeSelectedOnPage(): boolean {
    return this.offerVariantList.data.some(offer => this.selection.isSelected(offer));
  }

  toggleSelect(checked: boolean, offer: OfferGroupDomain<Offer>) {
    if (checked) {
      if (!this.selection.isSelected(offer)) {
        this.selection.select(offer);
      }
    } else {
      this.selection.deselect(offer);
    }
  }

  canEdit(): boolean {
    return this.authorizationService.checkAccess('edit||offermang') && !this.readOnlyMode;
  }

  getStatusClass(offer: OfferGroupDomain<Offer>): string {
    const status = offer?.Detail?.Status;
    return status === '0' ? 'statusPENDING' : 'status' + status;
  }

  getStatusText(offer: OfferGroupDomain<Offer>): string {
    const status = offer?.Detail?.Status;
    return status === '0' ? 'PENDING' : status || '';
  }
}
