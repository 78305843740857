<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
  <ng-container *ngIf="viewModel.offers.length > 0">
    <mat-checkbox *ngIf="canEdit()" (change)="toggleSelectAll($event.checked)" [checked]="isAllSelected()"
      [indeterminate]="isSomeSelectedOnPage() && !isAllSelected()">Select All</mat-checkbox>
    <div class="grid">
      <mat-card appearance="outlined" *ngFor="let offer of viewModel.offers; index as i">
        <mat-card-content>
          <span class="rank" *ngIf="offer?.Detail?.Rank">Rank : {{ offer.Detail.Rank }}</span>
          <div class="offer-status">
            <span [matMenuTriggerFor]="canEdit() ? menu : null" class="ellipsis-3-line {{ getStatusClass(offer) }}">
              {{ getStatusText(offer) }}
              <mat-icon *ngIf="canEdit()" class="icon">arrow_drop_down</mat-icon>
            </span>
          </div>
          <mat-menu #menu="matMenu" class="status-menu">
            <button type="button" mat-menu-item class="statusAPPROVED" [disabled]="offer?.Detail?.Status === 'APPROVED'"
              (click)="updateStatus(Status.APPROVED, offer)">
              APPROVED
            </button>
            <button type="button" mat-menu-item class="statusPENDING" [disabled]="offer?.Detail?.Status === 'PENDING'"
              (click)="updateStatus(Status.PENDING, offer)">
              PENDING
            </button>
            <button type="button" mat-menu-item class="statusSUPPRESSED"
              [disabled]="offer?.Detail?.Status === 'SUPPRESSED'" (click)="updateStatus(Status.SUPPRESSED, offer)">
              SUPPRESSED
            </button>
          </mat-menu>

          <div class="grid-col-span-2 offer-name" title="{{ offer?.Detail?.Name }}" fxLayout="row wrap"
            fxLayoutAlign="start start" fxLayoutGap="15px">
            <mat-checkbox *ngIf="!readOnlyMode" (click)="$event.stopPropagation()" fxFlex="30px" class="card-checkbox"
              (change)="toggleSelect($event.checked, offer)" [checked]="selection.isSelected(offer)">
            </mat-checkbox>
          </div>

          <div fxLayoutAlign="center center" class="offer-image">
            <img mat-card-md-image
              [src]="offer.FacingAsset ? utilityService.getOfferPreviewUrl(offer.FacingAsset, PreviewMode.Medium) : './assets/images/default-image.png'"
              alt="Offer image" (error)="onImageError($event)">
          </div>

          <div class="offer-content" fxLayout="column">
              <mat-form-field class="versions" appearance="outline">
                <mat-select [(value)]="offer.Detail.OfferVariantName" [disabled]="true" placeholder="Select Variant">
                  <mat-option [value]="offer.Detail.OfferVariantName">{{offer.Detail.OfferVariantName}}</mat-option>
                </mat-select>
              </mat-form-field>

            <div class="offer-headline">
              <span [innerHtml]="offer?.PricingPreviewHtml"></span>
            </div>
          </div>

          <div class="card-footer grid-col-span-2" fxLayout="row wrap" fxLayoutGap="15px">
            <span title="{{ offer?.OfferTagNames }}" class="card-footer-text w-100">
              Tags : {{ offer?.OfferTagNames }}</span>
            <span title="{{ offer?.VersionNames }}" class="card-footer-text version-codes w-100">
              Versions : {{ offer?.VersionNames }}</span>

            <div class="card-footer-text">
              <span class="footer-text">{{ offer?.EventOfferType }}</span>
              <span class="footer-text display">Client Key: <span
                  title="{{ offer?.Detail?.ClientKey }}">{{offer?.Detail?.ClientKey }}</span></span>
            </div>

            <div class="card-footer-btns">
              <button class="offer-button" type="button" mat-button color="accent" (click)="onEdit(offer.Detail.Id)">
                <span *ngIf="canEdit()">Edit</span>
                <span *ngIf="!canEdit()">View Details</span>
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>

  <ng-container *ngIf="viewModel?.offers?.length === 0">
    <table>
      <tr>
        <td class="no-records">
          <h2>No Records Found</h2>
        </td>
      </tr>
    </table>
  </ng-container>
</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>