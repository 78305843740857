import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private organizationService: OrganizationService,
    private httpClient: HttpClient
  ) { }

  getIntegrations() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Integrations/GetAllIntegrations`;
    return this.httpClient.get(url);
  }

  getIntegration(id: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Integrations/${id}`;
    return this.httpClient.get<any>(url);
  }

  getIntegrationSearch(clientKey: string, subscription: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Integrations/GetIntegrationSearch?clientkey=${clientKey}&subscription=${subscription}`;
    return this.httpClient.get<any>(url);
  }

  updateIntegration(integration: any, id: string) {
    if (id) {
      let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Integrations/${id}`;
      return this.httpClient.put(url, integration);
    } else {
      let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Integrations`;
      return this.httpClient.post(url, integration);
    }
  }
}
