import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material.module';
import { LoaderComponent } from './components/loader/loader.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { GlobalErrorHandler } from './global-error-handler';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { SpinnerOverlayService } from './services/spinner-overlay.service';
import { OrganizationDialogComponent } from './components/organization-dialog/organization-dialog.component';
import { MediaPreviewComponent } from './components/media-preview/media-preview.component';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ColorPickerModule } from 'ngx-color-picker';
import { RestrictControlDirective } from './directives/restrict-control.directive';
import { SharedAuthModule } from 'pr1-ui-components';
import { ResizeColumnDirective } from './directives/resizable-column.directive';
import { PrAuthGuard } from './auth-guard/pr.auth.guard';
import { ActivityLogDetailsDialogComponent } from './components/activity-log-details-dialog/activity-log-details-dialog.component';
import { RoleAuthGuard } from './auth-guard/role.auth.guard';

@NgModule({
  declarations: [
    LoaderComponent,
    ConfirmDialogComponent,
    ActivityLogDetailsDialogComponent,
    ErrorDialogComponent,
    SpinnerOverlayComponent,
    OrganizationDialogComponent,
    MediaPreviewComponent,
    RestrictControlDirective,
    ResizeColumnDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    ErrorDialogComponent,
    LoaderComponent,
    SpinnerOverlayComponent,
    ColorPickerModule,
    RestrictControlDirective,
    SharedAuthModule,
    ResizeColumnDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    ColorPickerModule,
    SharedAuthModule,
  ],
  providers: [GlobalErrorHandler, SpinnerOverlayService, PrAuthGuard, RoleAuthGuard],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fab, far, fas);
  }
}
