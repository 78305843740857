import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Observable, Subscription, combineLatest, forkJoin, map, of, tap } from 'rxjs';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, OrganizationService } from 'src/app/@shared';
import { User, UserDomain, UserService } from '../../..';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-admin-user',
  templateUrl: './add-admin-user.component.html',
  styleUrls: ['./add-admin-user.component.scss'],
})
export class AddAdminUserComponent<T extends User> implements OnInit {
  imageUrl = './assets/images/default-image.png';
  user$: Observable<T> = of({} as T);
  formGroup = new FormGroup({
    Detail: new FormGroup({
      clientKey: new FormControl<string>(''),
      id: new FormControl({ value: '0', disabled: true }, { nonNullable: true }),
      organizationName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100),]),
      accountOwner: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(255),]),
      accountAdmin: new FormControl<string>('', [
        Validators.required,
        Validators.email,
        Validators.minLength(1),
        Validators.maxLength(250),
      ])
    })
  });
  userId: string = '0';

  viewModel$ = combineLatest([
    this.userService.users$,
    this.userService.isLoading$,
    this.userService.totalRecords$,
    this.userService.page$,
  ]).pipe(
    map(([users, isLoading, totalRecords, page]) => {
      if (users.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.userService.page(page);
        this.userService.reload();
      }
      return { users, isLoading, totalRecords, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns: string[] = [
    'first_name',
    'last_name',
    'email'
  ];
  usersList: UserDomain<T>[] = [];


  constructor(private router: Router,
    private route: ActivatedRoute,
    private userService: UserService<T>,
    private matSnackBar: MatSnackBar,
    private ogService: OrganizationService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.formGroup.controls.Detail.controls.organizationName.disable();
    this.formGroup.controls.Detail.controls.accountOwner.disable();
    this.formGroup.controls.Detail.controls.accountAdmin.disable();
    this.userService.reload();
  }

  editUser(user: T) {
    this.userId = user.Id as string;
    if (this.userId && this.userId !== '0') {
      this.user$ = this.userService.getUser(this.userId).pipe(
        tap((user: any) => {
          user.Detail.accountAdmin = user.Detail.Email;
          user.Detail.accountOwner = user.Detail.FirstName + ' ' + user.Detail.LastName;
          user.Detail.organizationName = this.ogService.organization?.name;
          this.formGroup.patchValue(user as any);
          this.formGroup.markAllAsTouched();
        })
      );
    }
  }

  addUser() {
    this.router.navigate([`../user-management`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }


  getSelectedRecords(eventTypes: any) {
    if (eventTypes && eventTypes.length > 0) {
      return eventTypes.filter((x: any) => x.selected == true).map((i: any) => i.Id);
    } else {
      return [];
    }
  }

  save() {
    this.trimControlValues(this.formGroup);
    if (this.formGroup.valid) {
      const userData = this.formGroup?.value as any;
      for (let index = 0; index <= userData.length - 1; index++) {
        const currentOrg = userData[index].orgPath
        delete userData[index].orgPath;
        if (userData[index].Detail.Id == '0') {
          userData[index].Detail.Id = '';
        }
        this.userService.saveUser(userData[index] as any, currentOrg).subscribe({
          next: (response) => {
            this.matSnackBar.open(
              `${this.formGroup.controls.Detail.controls.organizationName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
            );
            this.userService.reload();
            this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
          },
          error: (error) => {
            if (error.status === 400) {
              const apiValidations: any = error.error;
              if (Array.isArray(apiValidations)) {
                apiValidations.forEach((validation: any) => {
                  if (this.formGroup?.get(validation.PropertyName)) {
                    const control = this.formGroup?.get(validation.PropertyName);
                    if (control) {
                      control.markAsTouched();
                      if (validation.PropertyName != 'Detail.ClientKey') {
                        control.setErrors({ invalid: validation.ErrorMessage });
                      }
                      this.matSnackBar.open(validation.ErrorMessage, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                    }
                  } else {
                    ///TODO: if we have cross field validation then show the validation error at the top of the screen
                    // if we have cross field validation then show the validation error at the top of the screen
                    // push general error messages to array this is displayed in a toast or dialog
                  }
                });
              } else {
                this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
              }


            }
          }
        });
      }
    }
  }

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  deleteRecord() {
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.organizationName}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.userService.deleteUsers([record]).subscribe({
            next: () => {
              this.matSnackBar.open(`${record.Detail.organizationName} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.userService.reload();
              this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
            },
            error: (error) => {
              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            }
          });
        }
      });
  }


  selectFiles(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = reader.result as string;
        // this.myForm.patchValue({
        //   fileSource: reader.result as string
        // });
      };
    }
  }

}