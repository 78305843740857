import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { OfferTag, OfferTagService } from '../..';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

const log = new Logger('OfferGroupTagsComponent');

interface DialogData {
  event: string;
  offerTags: OfferTag[];
}

@Component({
  selector: 'app-offer-group-tags',
  templateUrl: './offer-group-tags.component.html',
  styleUrls: ['./offer-group-tags.component.scss']
})

export class OfferGroupTagsComponent implements OnInit {
  search = new FormControl<string>('');
  viewModel$ = combineLatest([
    this.offerTagService.offerTagsExcludedExpired$,
    this.offerTagService.isLoading$,
    this.offerTagService.tagsTotalRecordsExcludedExpired$,
    this.offerTagService.page$,
  ]).pipe(
    map(([tags, isLoading, totalRecords, page]) => {
      if (tags.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.offerTagService.page(page);
        this.offerTagService.reload();
      }
      this.tagsList = new MatTableDataSource(tags);
      return { tags, isLoading, totalRecords, page }
    }),
  );
  tagsList: MatTableDataSource<OfferTag> = new MatTableDataSource();
  selection = new SelectionModel<OfferTag>(true, [], true, (tag1, tag2) => tag1.Id === tag2.Id);
  displayedColumns = ['select', 'OfferTagName'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<OfferGroupTagsComponent>,
    private offerTagService: OfferTagService<OfferTag>) { }

  ngOnInit(): void {
    log.debug('init');
    this.offerTagService.reload();
    if (this.dialogData?.offerTags?.length > 0) {
      this.selection = new SelectionModel<OfferTag>(true, this.dialogData.offerTags, true, (tag1, tag2) => tag1.Id === tag2.Id);
    }
  }

  ngOnDestroy() {
    this.offerTagService.resetPage();
  }

  onSearch(event: any) {
    this.offerTagService.search(event.target.value);
  }

  clearSearch() {
    this.search.setValue('');
    this.offerTagService.search('');
  }

  onSort(sortState: Sort): void {
    this.offerTagService.sort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.offerTagService.page(pageEvent);
  }

  closeDialog() {
    this.dialogRef.close();
    this.offerTagService.search('');
  }

  saveData() {
    this.dialogRef.close(this.selection.selected);
    this.offerTagService.search('');
  }

  isAllSelected(): boolean {
    return this.tagsList.data.every(tag => this.selection.isSelected(tag));
  }

  toggleSelectAll(checked: boolean): void {
    if (checked) {
      this.tagsList.data.forEach(tag => {
        if (!this.selection.isSelected(tag)) {
          this.selection.select(tag);
        }
      });
    } else {
      this.tagsList.data.forEach(tag => {
        this.selection.deselect(tag);
      });
    }
  }

  isSomeSelectedOnPage(): boolean {
    return this.tagsList.data.some(tag => this.selection.isSelected(tag));
  }

  toggleSelect(checked: boolean, tag: OfferTag) {
    if (checked) {
      if (!this.selection.isSelected(tag)) {
        this.selection.select(tag);
      }
    } else {
      this.selection.deselect(tag);
    }
  }

  getSelectedSectionRecords() {
    return this.selection.selected;
  }

}
