import { Component, Inject, OnInit } from '@angular/core';
import { DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { OfferPromoService, OfferPromo, PromoDomain, OfferTagService, OfferTag, OfferTagsResponseModel } from '../..';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

const log = new Logger('EditOfferTagsComponent');

interface DialogData {
  selectedRecordsDetails: any;
  eventId: string;
}

@Component({
  selector: 'app-edit-offer-tags',
  templateUrl: './edit-offer-tags.component.html',
  styleUrls: ['./edit-offer-tags.component.scss']
})

export class EditOfferTagsComponent implements OnInit {
  tagCtrl = new FormControl<string>('', [
    Validators.minLength(1),
    Validators.maxLength(50),
  ]);
  readOnlyMode = false;
  offerTagsResponse: OfferTagsResponseModel[] = [];
  removeTags = false;
  offerTagIds: string[] = [];
  updatedOfferTagIds: string[] = [];
  offerexists = false;
  offerTagsHistory: OfferTagsResponseModel[] = [];

  constructor(private offerTagService: OfferTagService<OfferTag>,
    private offerpromoService: OfferPromoService<OfferPromo, PromoDomain<OfferPromo>>,
    public dialogRef: MatDialogRef<EditOfferTagsComponent>,
    private matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    this.getTagsforOfferPromos();
  }

  getTagsforOfferPromos() {
    this.offerTagsHistory = [];
    let selectedRecords: any = [];
    for (let index = 0; index <= this.data.selectedRecordsDetails.length - 1; index++) {
      const activeVersion = this.data.selectedRecordsDetails[index].Variants.find(
        (x: any) => x.activeItem == true
      );
      if (
        !activeVersion ||
        (activeVersion &&
          activeVersion.Id === this.data.selectedRecordsDetails[index].DomainId)
      ) {
        const recordstoupdate = this.data.selectedRecordsDetails[index].Variants.map((x: any) => x.Id);
        selectedRecords = [...selectedRecords, ...recordstoupdate];
      } else if (
        activeVersion &&
        activeVersion.Id != this.data.selectedRecordsDetails[index].DomainId
      ) {
        const x = activeVersion;
        selectedRecords.push(x.Id);
      }
    }
    this.offerpromoService.getOfferPromoTags(this.data.eventId, selectedRecords).subscribe(((res: any) => {
      this.offerTagsResponse = res as any;
      if (this.offerTagsResponse && this.offerTagsResponse.length > 0) {
        this.offerTagsResponse.map((i: any) => {
          if (i.TagId)
            this.offerexists = true;
        });
      }
    }))
  }



  getDisplayValue(name: string | undefined) {
    if (name && name.toString().length > 20) {
      return name.substring(0, 20) + '...' + '; ';
    } else {
      return name + '; ';
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  tagSelected(tagId: string): void {
    if (this.updatedOfferTagIds?.length > 0 && this.updatedOfferTagIds.includes(tagId)) {
      return;
    }
    this.updatedOfferTagIds.push(tagId);
  }

  addTag(offerTag: OfferTag): void {
    this.updatedOfferTagIds.push(offerTag.Id);
    this.matSnackBar.open(`${offerTag.OfferTagName} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG);
  }

  removeTag(tagId: string): void {
    this.updatedOfferTagIds = this.updatedOfferTagIds.filter(id => id !== tagId);
  }

  undoTags() {
    if (this.offerTagsHistory && this.offerTagsHistory.length > 0) {
      if (this.removeTags) {
        this.offerTagsResponse = this.offerTagsHistory;
        this.offerTagsHistory = [];
        this.removeTags = false;
      } else {
        const lastItem = this.offerTagsHistory[this.offerTagsHistory.length - 1];
        if (lastItem) {
          const removedItem = this.offerTagsResponse.find((x: any) => x.TagName == lastItem.TagName && !x.TagId);
          if (removedItem) {
            removedItem.TagId = lastItem.TagId;
          }
          this.offerTagsHistory = this.offerTagsHistory.filter((x: any) => x.TagId !== lastItem.TagId);
        }
      }

    }
  }

  removeOfferTags(offertag: OfferTagsResponseModel) {
    const index = this.offerTagsResponse.findIndex((_tag) => _tag.TagId == offertag.TagId);
    this.offerTagsHistory.push(JSON.parse(JSON.stringify(this.offerTagsResponse[index])));
    if (index >= 0) {
      this.offerTagsResponse[index].TagId = '';
    }
  }

  removeAllTags() {
    if (this.offerTagsResponse && this.offerTagsResponse.length > 0) {
      if (this.offerTagsHistory && this.offerTagsHistory.length > 0) {
        const removedItems = this.offerTagsResponse.filter(item => item.TagId);
        this.offerTagsHistory = JSON.parse(JSON.stringify([...this.offerTagsHistory, ...removedItems]));
        this.offerTagsResponse.forEach((x: any) => x.TagId = '');
        this.removeTags = true;
      } else {
        this.offerTagsHistory = JSON.parse(JSON.stringify(this.offerTagsResponse));
        this.offerTagsResponse.forEach((x: any) => x.TagId = '');
        this.removeTags = true;
      }

    }
  }


  saveOfferTags() {
    const datatoSave = {
      ExistingOfferTags: this.offerTagsResponse,
      TagIdsToAdd: this.updatedOfferTagIds,
    }
    this.offerpromoService.massSaveofferTags(datatoSave).subscribe(res => {
      this.matSnackBar.open(
        `Offer Tags Saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
      );
      this.offerpromoService.reload();
      this.dialogRef.close();
    });
  }

}
