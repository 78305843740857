<ng-container *ngIf="viewModel$ | async as viewModel">
  <ng-container *ngIf="viewModel.promos.length > 0">
    <table mat-table [dataSource]="viewModel.promos" matSort (matSortChange)="onSort($event)" matSortDisableClear
      [matSortStart]="'desc'">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
          <mat-checkbox *ngIf="canEdit()" (change)="toggleSelectAll($event.checked)" [checked]="isAllSelected()"
            [indeterminate]="isSomeSelectedOnPage() && !isAllSelected()"></mat-checkbox>
        </th>
        <td class="no-ellipsis" mat-cell *matCellDef="let record">
          <mat-checkbox *ngIf="canEdit()" (click)="$event.stopPropagation()"
            (change)="toggleSelect($event.checked, record)" [checked]="selection.isSelected(record)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Image Column -->
      <ng-container matColumnDef="Image">
        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
          Preview
        </th>
        <td mat-cell *matCellDef="let record">
          <img mat-card-md-image
            [src]="record?.FacingAsset ? utilityService.getOfferPreviewUrl(record?.FacingAsset, PreviewMode.Thumbnail) : './assets/images/default-image.png'"
            alt="Offer image" (error)="onImageError($event)">
        </td>
      </ng-container>

      <!-- OfferName Column -->
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"
          [resizeColumn]="true">Name</th>
        <td mat-cell *matCellDef="let record"> {{ record?.Detail?.Name }}</td>
      </ng-container>

      <!-- Version Column -->
      <ng-container matColumnDef="Version">
        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Variant</th>
        <td mat-cell *matCellDef="let record; let i = index;">
          <mat-form-field class="versions" appearance="outline">
            <mat-select [(value)]="record.Detail.OfferVariantName" [disabled]="true" placeholder="Select Variant">
              <mat-option [value]="record.Detail.OfferVariantName">{{record.Detail.OfferVariantName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Headline Column -->
      <ng-container matColumnDef="Headline">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Headline"
          [resizeColumn]="true">Headline</th>
        <td mat-cell *matCellDef="let record">{{ record?.Detail?.Headline }}</td>
      </ng-container>

      <!-- BodyCopy Column -->
      <ng-container matColumnDef="BodyCopy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by BodyCopy"
          [resizeColumn]="true">Body Copy</th>
        <td mat-cell *matCellDef="let record">{{ record?.Detail?.BodyCopy }}</td>
      </ng-container>

      <!-- EventType Column -->
      <ng-container matColumnDef="EventType">
        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Type</th>
        <td mat-cell *matCellDef="let record">{{ record?.EventOfferType }}</td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="Status">
        <th style="width:120px" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status"
          [resizeColumn]="true">Status</th>
        <td [matMenuTriggerFor]="canEdit() ? menu: null" mat-cell *matCellDef="let record">
          <div class="offer-status column-status">
            <span [matMenuTriggerFor]="canEdit() ? menu : null" class="ellipsis-3-line {{ getStatusClass(record) }}">
              {{ getStatusText(record) }}
              <mat-icon *ngIf="canEdit()" class="icon">arrow_drop_down</mat-icon>
            </span>
          </div>
          <mat-menu #menu="matMenu" class="status-menu">
            <button type="button" mat-menu-item class="statusAPPROVED"
              [disabled]="record?.Detail?.Status === 'APPROVED'"
              (click)="updateStatus(Status.APPROVED, record)">APPROVED
            </button>
            <button type="button" mat-menu-item class="statusPENDING" [disabled]="record?.Detail?.Status === 'PENDING'"
              (click)="updateStatus(Status.PENDING, record)">PENDING
            </button>
            <button type="button" mat-menu-item class="statusSUPPRESSED"
              [disabled]="record?.Detail?.Status === 'SUPPRESSED'"
              (click)="updateStatus(Status.SUPPRESSED, record)">SUPPRESSED
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <!-- Rank Column -->
      <ng-container matColumnDef="Rank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Rank"
          [resizeColumn]="true">Rank</th>
        <td mat-cell *matCellDef="let record">{{ record?.Detail?.Rank }}</td>
      </ng-container>

      <!-- VersionCodes Column -->
      <ng-container matColumnDef="VersionCodes">
        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Versions</th>
        <td mat-cell *matCellDef="let record">{{ record?.Variants }}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef style="padding-left: 20px;">Actions</th>
        <td mat-cell *matCellDef="let record">
          <button class="offer-button" type="button" mat-button color="accent" (click)="onEdit(record.Detail.Id)">
            <span *ngIf="canEdit()">Edit</span>
            <span *ngIf="!canEdit()">View Details</span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-container>

  <ng-container *ngIf="viewModel?.promos?.length === 0">
    <table>
      <tr>
        <td class="no-records">
          <h2>No Records Found</h2>
        </td>
      </tr>
    </table>
  </ng-container>
</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>