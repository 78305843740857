import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from '../services/authorization.service';

@Injectable()
export class RoleAuthGuard  {
    constructor(private router: Router, private authService: AuthorizationService) { }
    
    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): any {
        let accessData = _route.routeConfig?.data as any;
        setTimeout(() => {
            if (accessData && accessData.accessDetail && !this.authService.checkAccess(accessData.accessDetail)) {
                this.router.navigate(["noaccess"]);
            }
        }, 2000);
        return true;
    }
}