import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, map, Subscription, catchError, of } from 'rxjs';
import { Logger, PreviewMode, UtilityService } from 'src/app/@shared';
import { OfferPromo, PromoDomain, OfferPromoService, OfferService, Offer, OfferDomain, OfferGroupDomain, } from '../..';
import { Status } from '../../models/offer-promo-status';
import { AuthorizeControlService } from 'pr1-ui-components';
import { ActivatedRoute, Router } from '@angular/router';
import { EventWorkflowService } from '../../services/event-workflow.service';
import { MatTableDataSource } from '@angular/material/table';

const log = new Logger('VersionOfferCardsComponent');

@Component({
  selector: 'app-version-offer-cards',
  templateUrl: './version-offer-cards.component.html',
  styleUrls: ['./version-offer-cards.component.scss'],
})
export class VersionOfferCardsComponent<TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>, T extends Offer, TOfferDomain extends OfferDomain<Offer>> implements OnInit, AfterViewInit, OnDestroy {
  Status: typeof Status = Status;
  PreviewMode = PreviewMode;

  offerVariantList: MatTableDataSource<OfferGroupDomain<Offer>> = new MatTableDataSource();
  selection = new SelectionModel<OfferGroupDomain<Offer>>(true, [], true, (o1, o2) => o1.DomainId === o2.DomainId);
  readOnlyMode = true;
  private subscriptions: Subscription[] = [];

  viewModel$ = combineLatest([
    this.offerService.getofferChildVersions$,
    this.offerService.isLoading$,
  ]).pipe(
    map(([offers, isLoading]) => {
      this.offerVariantList = new MatTableDataSource(offers);
      return { offers, isLoading };
    }),
    catchError(error => {
      log.error('Error loading view model', error);
      return of({ offers: [], isLoading: false });
    })
  );

  constructor(
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    public utilityService: UtilityService,
    private authorizationService: AuthorizeControlService,
    public offerService: OfferService<T, TOfferDomain>,
    private router: Router,
    private route: ActivatedRoute,
    private ewService: EventWorkflowService
  ) { }

  ngOnInit(): void {
    log.debug('init');

    this.readOnlyMode = this.offerpromoService.getOfferDetailsReadOnlyValue();
    this.subscriptions.push(this.offerpromoService.offerDetailsReadOnlyMode$.subscribe((res) => {
      this.readOnlyMode = res;
    }));
  }
  
  ngAfterViewInit() {
    if (this.ewService.readOnlyMode != null) {
      this.readOnlyMode = this.ewService.readOnlyMode;
      return;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  
  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = './assets/images/default-image.png'; // Path to your default image
  }
  
  canEdit(): boolean {
    return this.authorizationService.checkAccess('edit||offermang') && !this.readOnlyMode;
  }

  getSelectedSectionRecords() {
    return this.selection.selected;
  }

  clearSelection() {
    this.selection.clear();
  }

  updateStatus(status: Status, row: OfferGroupDomain<Offer>) {
    let selectedRecordsDetails: OfferGroupDomain<Offer>[] = [JSON.parse(JSON.stringify(row))];
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      selectedRecordsDetails = selectedRecordsDetails.map((x: any) => {
        x.Detail.Status = status;
        return x.Detail;
      });
      this.offerpromoService.updateStatus(selectedRecordsDetails).subscribe({
        next: () => {
          this.offerService.reload();
          this.offerpromoService.reload();
          this.clearSelection();
          this.offerpromoService.updateRecordStatus(new Date());
        },
      });
    }
  }

  isAllSelected(): boolean {
    return this.offerVariantList.data.every(offer => this.selection.isSelected(offer));
  }

  toggleSelectAll(checked: boolean): void {
    if (checked) {
      this.offerVariantList.data.forEach(offer => {
        if (!this.selection.isSelected(offer)) {
          this.selection.select(offer);
        }
      });
    } else {
      this.offerVariantList.data.forEach(offer => {
        this.selection.deselect(offer);
      });
    }
  }

  isSomeSelectedOnPage(): boolean {
    return this.offerVariantList.data.some(offer => this.selection.isSelected(offer));
  }

  toggleSelect(checked: boolean, offer: OfferGroupDomain<Offer>) {
    if (checked) {
      if (!this.selection.isSelected(offer)) {
        this.selection.select(offer);
      }
    } else {
      this.selection.deselect(offer);
    }
  }

  onEdit(id: string) {
    this.router.navigate([`../../${id}/details`], { relativeTo: this.route });
  }

  getStatusClass(offer: OfferGroupDomain<Offer>): string {
    const status = offer?.Detail?.Status;
    return status === '0' ? 'statusPENDING' : 'status' + status;
  }

  getStatusText(offer: OfferGroupDomain<Offer>): string {
    const status = offer?.Detail?.Status;
    return status === '0' ? 'PENDING' : status || '';
  }
}
