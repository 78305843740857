import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, combineLatest, map } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  ConfirmDialogComponent,
  NotificationService,
  OrganizationService,
} from 'src/app/@shared';
import { SystemWorkflowService } from '../../services/admin-workflow.service';
import { AddWorkflowComponent } from '../..';

@Component({
  selector: 'app-workflow-management',
  templateUrl: './workflow-management.component.html',
  styleUrls: ['./workflow-management.component.scss'],
})
export class WorkflowManagementComponent implements OnInit, OnDestroy {
  viewModel$ = combineLatest([
    this.ewService.Templates$!,
    this.ewService.isLoading$!,
    this.ewService.totalRecords$!,
    this.ewService.page$!,
  ]).pipe(
    map(([templates, isLoading, totalRecords, page]) => {
      if (templates.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.ewService.page(page);
        this.ewService.reload();
      }
      return { templates, isLoading, totalRecords, page };
    })
  );
  editWorkflow = false;
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [
    'select',
    'template-name',
    'template-group',
    'category',
    'actions',
  ];

  public subscription$ = new Subscription();
  eventId: any;
  showActions = false;
  selection = new SelectionModel<any>(true, []);
  selectedTemplateId: any;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  allSelected: any;
  selectedTemplates: any;
  selectedTemplateIdsList: any;
  allWorkflowGroups: any;
  searchText = '';
  workflowId: any;

  assignPaginator() {
    // if (!this.dataSource.paginator) {
    this.dataSource.paginator = this.paginator!;
    // }
  }

  constructor(
    public ewService: SystemWorkflowService,
    public dialog: MatDialog,
    public auth: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialog,
    public orgService: OrganizationService,
    public notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((p: any) => {
      if (p.workflowId && p.workflowId.trim().length > 0) {
        this.selectedTemplateId = p.workflowId;
        this.editWorkflow = true;
      } else {
        this.selectedTemplateId = undefined;
        this.editWorkflow = false;
      }
    });

    const getControlsRefDataInterval = setInterval(() => {
      if (this.orgService.silentAuthToken.length > 0) {
        this.getControlsRefData();
        clearInterval(getControlsRefDataInterval);
      }
    }, 1000);

    this.subscription$.add(
      this.ewService.templatesSelected.subscribe((data: any) => {
        this.dataSource = new MatTableDataSource<any>(data);
        this.assignPaginator();
      })
    );
  }

  getMasterTemplate(token: any) {
    this.subscription$.add(
      this.ewService.getMasterTemplate().subscribe((data: any) => {
        data = data?.Value?.map((d: any) => {
          return {
            id: d.Id,
            templateName: d.Name,
            eventType: d.WorkflowCategory ? d.WorkflowCategory : '-',
            channel: d.WorkflowGroup ? d.WorkflowGroup : '-',
            user: d.Id,
          };
        });

        this.dataSource = new MatTableDataSource<any>(data);
        this.assignPaginator();
      })
    );
  }

  openAddTemplateViewDialog() {
    const dialogRef = this.dialog.open(AddWorkflowComponent);
    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      })
    );
  }

  navigateToNewTemplate() {
    this.selectedTemplateId = '';
    this.editWorkflow = true;
  }

  onTemplatesSelected(selectedTemplates: any) {
    this.dataSource = new MatTableDataSource<any>(selectedTemplates);
    this.assignPaginator();
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  // toggleAllRows(e: any) {
  //   if (e.checked == false) {
  //     this.showActions = false;
  //     this.selection.clear();
  //     return;
  //   }

  //   this.selection.select(...this.dataSource.data);
  //   this.showActions = true;
  //   // this.handleShowActions();
  // }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(e: any, dataSource: any) {
    this.allSelected = e.checked;
    if (e.checked == false) {
      this.selectedTemplates = [];
      this.selection.clear();
    } else {
      const selectedIndexes = [];
      for (let i = 0; i < dataSource.length; i++) {
        selectedIndexes.push(dataSource[i].id);
      }
      this.selectedTemplates = selectedIndexes;
      this.selection.select(...dataSource);
    }

    this.showActions = this.selection.selected.length > 0;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }

  // selectionToggle(row: any) {
  //   this.selection.toggle(row);
  //   this.handleShowActions();
  // }

  selectionToggle(e: any, rowIndex: number, dataSource: any) {
    const u = dataSource[rowIndex];

    if (this.selection.isSelected(u)) {
      this.selection.deselect(u);
    } else {
      this.selection.select(u);
    }
    this.showActions = this.selection.selected.length > 0;
  }

  handleShowActions() {
    if (this.selection.selected.length > 0) {
      this.showActions = true;
    } else {
      this.showActions = false;
    }
  }

  closeEditWorkflow(e: any) {
    this.editWorkflow = false;
    if (this.selectedTemplateId) {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  onEditWorkflow(selectedWorkflow: any) {
    this.selectedTemplateId = selectedWorkflow.Id;
    this.router.navigate([`${this.selectedTemplateId}`], {
      relativeTo: this.route,
    });
    // this.editWorkflow = true;
  }

  onPage(e: PageEvent): void {
    this.selection.clear();
    this.ewService.page(e);
  }

  cloneTemplates() {
    const selectedTemplateIds = this.selection.selected.map((s) => s.Id);
    const message =
      selectedTemplateIds.length > 1
        ? `Are you sure you want to clone these ${selectedTemplateIds.length} templates?`
        : `Are you sure you want to clone this template?`;

    const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
      data: {
        message: message,
        confirmText: 'Yes',
        cancelText: 'No',
      },
    });
    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.subscription$.add(
            this.ewService
              .cloneWorkflowTemplates(selectedTemplateIds)
              .subscribe((resp: any) => {
                this.selection.clear();
                this.showActions = false;
                this.ewService.reload();
              })
          );
        }
      })
    );
  }

  deleteTemplates() {
    const selectedTemplateIds = this.selection.selected.map((s) => s.Id);
    const message =
      selectedTemplateIds.length > 1
        ? `Are you sure you want to delete these ${selectedTemplateIds.length} templates?`
        : `Are you sure you want to delete this template?`;

    const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
      data: {
        message: message,
        confirmText: 'Yes',
        cancelText: 'No',
      },
    });

    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          const deleteTemplatesSubs = new Subscription();

          // loop through selected templates & delete each one
          for (let i = 0; i < selectedTemplateIds.length; i++) {
            const templateId = selectedTemplateIds[i];
            deleteTemplatesSubs.add(
              this.ewService.deleteWorkflowTemplate(templateId).subscribe({
                next: (resp: any) => {
                  if (i == selectedTemplateIds.length - 1) {
                    deleteTemplatesSubs.unsubscribe();
                    this.selection.clear();
                    this.showActions = false;
                    this.selection.clear();

                    const deleteSuccessMsg =
                      selectedTemplateIds.length > 1
                        ? `Deleted ${selectedTemplateIds.length} templates.`
                        : 'Deleted template.';

                    this.notificationService.showSuccess(deleteSuccessMsg);
                    this.ewService.reload();
                  }
                },
                error: (e: any) =>
                  console.log(`deleteWorkflowTemplate - error: `, e),
              })
            );
          }
        }
      })
    );
  }

  getControlsRefData() {
    this.subscription$.add(
      this.ewService
        .getRefDataForEditWorkflowControls(this.orgService.silentAuthToken)
        .subscribe((data: any) => {
          this.allWorkflowGroups = data.workflowGroups;
        })
    );
  }

  getCategoryNameFromId(id: string) {
    const i = this.allWorkflowGroups?.findIndex((g: any) => g?.Id == id);
    if (i > -1) {
      return this.allWorkflowGroups[i]?.Name;
    }
    return id;
  }

  onSearch(event: any) {
    this.ewService.search(event.target.value);
  }

  clearSearch() {
    this.searchText = '';
    this.ewService.search('');
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
