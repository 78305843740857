import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_PAGING, Logger } from 'src/app/@shared';
import { EventService, Offer, Event, OfferDomain, OfferPromo, OfferPromoService, PromoDomain, CloneOfferComponent, LabelType, MontageBuilderService, OfferVariantListModel, } from '../..';
import { OfferService } from 'src/app/modules/standard/v1';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
const log = new Logger('OfferComponent');

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent<TOfferDomain extends OfferDomain<Offer>, TOfferPromo extends OfferPromo, TEvent extends Event, TPromoDomain extends PromoDomain<OfferPromo>> implements OnInit, OnDestroy {
  offerId: string = '0';
  versionNames: string[] = [];
  variantsList: any[] = [];
  public labelType: number = 0;
  newVersionName = new FormControl<any>(null);
  VersionCtrl = new FormControl<string>('');
  applyChanges = new FormControl<boolean>(false);
  offerTypeIdsByEventList: (OfferVariantListModel | null)[] = [];
  currentTypeIndex: number = -1;
  subscriptions: Subscription = new Subscription();
  eventListPageDetails: any = DEFAULT_PAGING;
  public offerlistModelSub: any = null;
  public disableNewVersion: boolean = false;
  public variantsCount: number = 0;
  public activeNavItem: string = '';
  public LabelType = LabelType;
  viewModel$ = combineLatest([
    this.eventService.getEvent(this.route.snapshot.params['eventId']),
    this.montageBuilderService.montageBuilderStatus$
  ]).pipe(
    map(([event, montageBuilderStatus]) => {
      return { event, montageBuilderStatus };
    })
  );

  readOnlyMode: any = null;

  constructor(
    public route: ActivatedRoute,
    public offerserv: OfferService<any, TOfferDomain>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private router: Router,
    private eventService: EventService<TEvent>,
    private dialog: MatDialog,
    private montageBuilderService: MontageBuilderService
  ) { }

  ngOnInit(): void {
    this.offerId = this.route.snapshot.params['offerId'];
    this.offerpromoService.eventId = this.route.snapshot.params['eventId'];
    this.eventListPageDetails = this.offerpromoService.getPageDetails();
    this.offerTypeIdsByEventList = [];
    if (this.offerpromoService.getTypeIdsByEventInitialData()) {
      this.offerTypeIdsByEventList = this.offerpromoService.getTypeIdsByEventInitialData();
    } else {
      this.searchAllEventOfferTypeIdsByEvent();
    }

    this.montageBuilderService.montageBuilderStatusBehaviorSubject.next(false);
    this.montageBuilderService.montageGetEventTypeBehaviourSubject.next('');
    log.debug('init');
    this.offerserv.newVersion = '';
    if (
      this.route.snapshot.routeConfig &&
      this.route.snapshot.routeConfig.path === 'promos'
    ) {
      this.montageBuilderService.montageGetEventTypeBehaviourSubject.next('PROMO');
      this.labelType = LabelType.PROMO;
    } else if (
      this.route.snapshot.routeConfig &&
      this.route.snapshot.routeConfig.path === 'offergroups'
    ) {
      this.montageBuilderService.montageGetEventTypeBehaviourSubject.next('OFFERGROUP');
      this.labelType = LabelType.OFFERGROUP;
    } else {
      this.labelType = LabelType.OFFER;
      this.montageBuilderService.montageGetEventTypeBehaviourSubject.next('OFFER');
    }

    this.getVariants('');
    this.checkIfReadOnlyMode();
    this.offerserv.triggerNewVariant$
      .pipe(
        distinctUntilChanged(
          (x: any, y: any) => x === y
        ))
      .subscribe((change) => {
        if (change && !this.offerserv.dialogueOpened) {
          this.openDialog();
          this.offerserv.addNewVariant = false;
        }
      });
  }

  ngOndestroy() {
    this.subscriptions.unsubscribe();
  }

  searchAllEventOfferTypeIdsByEvent(): void {
    if (!this.eventListPageDetails) {
      console.error('Page details not available for initialization.');
      return;
    }

    const subscription = this.offerpromoService.searchAllEventOfferTypeIdsByEvent(this.eventListPageDetails).subscribe({
      next: (res: { '@odata.count': number; value: OfferVariantListModel[] }) => {
        const totalRecords = res['@odata.count'];
        const eventOfferTypeIds: OfferVariantListModel[] = res.value;

        if (!this.offerTypeIdsByEventList || this.offerTypeIdsByEventList.length !== totalRecords) {
          this.offerTypeIdsByEventList = Array(totalRecords).fill(null);
        }

        this.offerTypeIdsByEventList.splice(
          this.eventListPageDetails.pageIndex * this.eventListPageDetails.pageSize,
          eventOfferTypeIds.length,
          ...eventOfferTypeIds
        );

        const offerId = this.route.snapshot.params['offerId'];
        const index = this.findIndexById(this.offerTypeIdsByEventList, offerId);

        if (index > -1) {
          this.currentTypeIndex = index;

          if (this.shouldLoadNextPage(this.currentTypeIndex)) {
            this.loadPageIfNeeded(this.currentTypeIndex + 1, 'next');
          } else if (this.shouldLoadPreviousPage(this.currentTypeIndex)) {
            this.loadPageIfNeeded(this.currentTypeIndex - 1, 'previous');
          }
        } else if (this.eventListPageDetails.pageIndex < Math.ceil(totalRecords / this.eventListPageDetails.pageSize)) {
          this.eventListPageDetails.pageIndex++;
          this.searchAllEventOfferTypeIdsByEvent();
        } else {
          console.error('Reached the last page, but the record was not found.');
        }
      },
      error: (err) => {
        console.error('Error fetching event offer type IDs:', err);
      },
    });

    this.subscriptions.add(subscription);
  }

  // Check if the next page needs to be loaded
  private shouldLoadNextPage(currentIndex: number): boolean {
    return (
      this.offerTypeIdsByEventList[currentIndex + 1] == null &&
      currentIndex + 1 < this.offerTypeIdsByEventList.length
    );
  }

  // Check if the previous page needs to be loaded
  private shouldLoadPreviousPage(currentIndex: number): boolean {
    return (
      this.offerTypeIdsByEventList[currentIndex - 1] == null &&
      currentIndex - 1 >= 0
    );
  }

  // Navigate to the next offer
  nextOffer() {
    const offerId = this.route.snapshot.params['offerId'];
    this.currentTypeIndex = this.findIndexById(this.offerTypeIdsByEventList, offerId);

    if (this.currentTypeIndex === -1 || this.currentTypeIndex >= this.offerTypeIdsByEventList.length - 1) {
      console.warn('Cannot navigate to the next offer: Invalid or last index.');
      return;
    }

    if (this.shouldLoadNextPage(this.currentTypeIndex)) {
      this.loadPageIfNeeded(this.currentTypeIndex + 1, 'next');
    }

    const nextType = this.offerTypeIdsByEventList[++this.currentTypeIndex];
    if (nextType) {
      this.navigateToNextItem(nextType?.Label, nextType?.VariantIds[0]);
    }
  }

  // Navigate to the previous offer
  previousOffer() {
    const offerId = this.route.snapshot.params['offerId'];
    this.currentTypeIndex = this.findIndexById(this.offerTypeIdsByEventList, offerId);

    if (this.currentTypeIndex === -1 || this.currentTypeIndex <= 0) {
      console.warn('Cannot navigate to the previous offer: Invalid or first index.');
      return;
    }

    if (this.shouldLoadPreviousPage(this.currentTypeIndex)) {
      this.loadPageIfNeeded(this.currentTypeIndex - 1, 'previous');
    }

    const prevType = this.offerTypeIdsByEventList[--this.currentTypeIndex];
    if (prevType) {
      this.navigateToNextItem(prevType?.Label, prevType?.VariantIds[0]);
    }
  }

  private loadPageIfNeeded(index: number, direction: 'next' | 'previous') {
    const pageIndex = this.getPageIndex(index, this.eventListPageDetails.pageSize, direction === 'next');

    if (pageIndex < 0 || pageIndex * this.eventListPageDetails.pageSize >= this.offerTypeIdsByEventList.length) {
      console.warn('Page index out of bounds:', pageIndex);
      return;
    }

    this.eventListPageDetails.pageIndex = pageIndex;
    this.searchAllEventOfferTypeIdsByEvent();
  }

  findIndexById(list: (OfferVariantListModel | null)[], value: string): number {
    return list.findIndex(
      (item) => item?.BaseId === value || item?.VariantIds?.includes(value)
    );
  }

  getPageIndex(itemPosition: number, pageSize: number, nextPage: boolean = true): number {
    if (pageSize <= 0) {
      console.error('Invalid page size:', pageSize);
      return -1;
    }
    return Math.floor((itemPosition + (nextPage ? 1 : -1)) / pageSize);
  }

  navigateToNextItem(type: string, id: string): void {
    const typeRouteMap: { [key: string]: string } = {
      Offer: `../${id}/details`,
      Promo: `../${id}/promos/promodetails`,
      OfferGroup: `../${id}/offergroups/offergrpdetails`,
    };

    if (!typeRouteMap[type]) {
      console.error('Invalid navigation type:', type);
      return;
    }

    const currentPath = this.route.snapshot.routeConfig?.path;
    const isInSubRoute = currentPath === 'promos' || currentPath === 'offergroups';

    let url = typeRouteMap[type];
    if (isInSubRoute) {
      url = '../' + url;
    }

    this.resetMontageBuilderProps();
    this.router.navigate([url], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  checkIfReadOnlyMode() {
    this.readOnlyMode = this.offerpromoService.getOfferDetailsReadOnlyValue();
    this.offerpromoService.offerDetailsReadOnlyMode$.subscribe(
      (res) => (this.readOnlyMode = res)
    );
  }

  getVariants(variantName: string) {
    if (this.offerId != '0') {
      if (this.labelType == LabelType.PROMO) {
        this.offerpromoService.getPromoVersions(this.offerId).subscribe((res: any) => {
          this.updateVariantsValue(res, variantName);
        });
      } else {
        this.offerserv.getOfferVersions(this.offerId).subscribe((res: any) => {
          this.updateVariantsValue(res, variantName);
        });
      }
    }
  }

  updateVariantsValue(res: any, variantName: string) {
    if (res) {
      this.offerserv.selection.clear();
      this.variantsList = res.value;
      this.variantsCount = res.value.length;
      this.offerserv.variantsCount = res.value.length;
      if (variantName) {
        const versiontoAdd = { Id: new Date(), OfferVariantName: variantName };
        this.variantsList.push(versiontoAdd);
        this.newVersionName.patchValue(versiontoAdd);
      } else {
        const offer = this.variantsList.find((x: any) => x.Id === this.offerId);
        if (offer) {
          this.newVersionName.patchValue(offer);
          this.offerserv.selection.select(offer);
        }
      }
    }
  }

  isAllSelected() {
    const numSelected = this.offerserv.selection.selected.length;
    const numRows = this.variantsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.offerserv.selection.clear()
      : this.variantsList.forEach((row) =>
        this.offerserv.selection.select(row)
      );

    if (!this.isAllSelected()) {
      const variant = this.variantsList.find(
        (x) => x.Id === this.newVersionName.value.Id
      );
      this.offerserv.selection.select(variant);
    }
  }

  removeTag(tag: any): void {
    const rows = this.offerserv.selection.selected.filter(
      (x) => x.Id != tag.Id
    );
    this.offerserv.selection.clear();
    rows.forEach((row) => this.offerserv.selection.select(row));
  }

  openDialog() {
    const offer = this.offerserv.getCurrentRecord;
    const selectedVersions = this.offerserv.SelectedOfferVersionsList;
    if (offer) {
      const dialogRef = this.dialog.open(CloneOfferComponent, {
        width: '30%',
        height: '40%',
        data: { offerName: offer?.Detail?.Name, eventId: this.route.snapshot.params['eventId'], selectedVersionsList: selectedVersions },
      });
      this.offerserv.dialogueOpened = true;
      dialogRef.afterClosed().subscribe((res) => {
        // console.log('Response', res);
        this.offerserv.setSelectedOfferVersionsList = [];
        this.offerserv.dialogueOpened = false;
        if (res && res.formvalues && res.formvalues.Detail && res.formvalues.Detail.Name) {
          this.offerserv.newVersion = res.formvalues.Detail.Name;
          this.offerserv.selectedVersionsIds = res.selectedIds.map((x: any) => x.Id);
          this.versionNames.push(res.formvalues.Detail.Name);
          this.newVersionName.patchValue(res.formvalues.Detail.Name);
          this.disableNewVersion = true;
          this.goToOfferPromo(offer, res.formvalues.Detail.Name);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.offerserv.currentRecord = {};
    this.offerserv.newVersion = '';
    this.offerserv.addNewVariant = null;
    this.offerserv.dialogueOpened = false;
  }

  onSave() {
    this.offerserv.setSaveorCancel('1');
    this.disableNewVersion = false;
    this.offerserv.newVersion = '';
  }

  onCancel() {
    this.offerserv.setSaveorCancel('0');
    this.offerserv.newVersion = '';
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    this.getVariants('');
  }

  tagSelected(event: MatAutocompleteSelectedEvent) { }

  resetMontageBuilderProps() {
    this.montageBuilderService.montageBuilderStatusBehaviorSubject.next(false);
    this.montageBuilderService.selectedOfferProductsBehaviorSubject.next([]);
    this.montageBuilderService.selectedOfferAssetsBehaviorSubject.next([]);
    this.montageBuilderService.montageBuilderResponseBehaviorSubject.next({});
  }

  goToOfferPromo(OfferPromo: any, variantName?: string) {
    let urltoOpen = '';
    if (OfferPromo) {
      if (OfferPromo.EventOfferType === 'OFFERGROUP') {
        this.labelType = LabelType.OFFERGROUP;
        urltoOpen = `../${OfferPromo.DomainId}/offergroups/offergrpdetails`;
      } else {
        this.labelType = OfferPromo.EventOfferType === 'PROMO' ? LabelType.PROMO : LabelType.OFFER;
        this.getVariants(variantName as string);
        urltoOpen = OfferPromo.EventOfferType === 'PROMO' ? `../${OfferPromo.DomainId}/promos/promodetails` : `../${OfferPromo.DomainId}/details`;
      }

      if (
        this.route.snapshot.routeConfig &&
        (this.route.snapshot.routeConfig.path === 'promos' || this.route.snapshot.routeConfig.path === 'offergroups')
      ) {
        urltoOpen = '../' + urltoOpen;
      }
      console.log(urltoOpen);
      this.router
        .navigate([urltoOpen], { relativeTo: this.route })
        .then((x) => { });
    }
  }

  getSelectedVersionDetail(event: any) {
    if (this.variantsList && this.variantsList.length > 0) {
      this.offerserv.newVersion = '';
      const variantName = this.variantsList.find(
        (x) => x.Id === event.value.Id
      );
      if (variantName && variantName.Id) {
        this.offerId = variantName.Id;
        let idtoOpen = {
          DomainId: variantName.Id,
          EventType: this.labelType == LabelType.PROMO ? 'PROMO' : 'OFFER',
        };
        this.goToOfferPromo(idtoOpen);
      }
    }
    this.disableNewVersion = false;
  }

  setCurrentActiveItem(item: string) {
    this.activeNavItem = item;
  }
}
