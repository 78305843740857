import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Shell } from './modules/shell';

const routes: Routes = [
  Shell.childRoutes([
    //{ path: '', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
    { path: 'standard', loadChildren: () => import('./modules/standard/v1/standard.v1.module').then(m => m.StandardV1Module) },
    { path: 'kroger', loadChildren: () => import('./modules/organizations/kroger/v1/kroger.v1.module').then(m => m.KrogerV1Module) },
    { path: 'riteaid', loadChildren: () => import('./modules/organizations/riteaid/v1/riteaid.v1.module').then(m => m.RiteaidV1Module) },
    { path: 'walgreens', loadChildren: () => import('./modules/organizations/walgreens/v1/walgreens.v1.module').then(m => m.WalgreensV1Module) },
    { path: 'demo', loadChildren: () => import('./modules/organizations/demo/v1/demo.v1.module').then(m => m.DemoV1Module) },
    { path: 'qaautomation', loadChildren: () => import('./modules/organizations/qaautomation/v1/qaautomation.v1.module').then(m => m.QaAutomationV1Module) },
    { path: 'unfi', loadChildren: () => import('./modules/organizations/unfi/v1/unfi.v1.module').then(m => m.UnfiV1Module) },
    { path: 'shopify', loadChildren: () => import('./modules/organizations/shopify/v1/shopify.v1.module').then(m => m.ShopifyV1Module) },
    { path: 'arg', loadChildren: () => import('./modules/organizations/arg/v1/arg.v1.module').then(m => m.ARGV1Module) },

  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled', paramsInheritanceStrategy: 'always',  useHash: true })], //enableTracing: true
  exports: [RouterModule]
})

export class AppRoutingModule { }
