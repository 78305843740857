import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, combineLatest, map, of, tap } from 'rxjs';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, OrganizationService } from 'src/app/@shared';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-edit-integration-dialog',
  templateUrl: './edit-integration-dialog.component.html',
  styleUrls: ['./edit-integration-dialog.component.scss'],
})
export class EditIntegrationDialogComponent implements OnInit {
  integration$: Observable<any> = of({} as any);
  currentData: any;
  formGroup = new FormGroup({
    clientKey: new FormControl<string>(''),
    id: new FormControl({ value: '0', disabled: true }, { nonNullable: true }),
    OrganizationName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    ClientId: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    AIClientKey: new FormControl<string>('', [Validators.required]),
    TemplateId: new FormControl<string>('', [Validators.required]),
    Endpoint: new FormControl<string>('', [Validators.required]),
    offer: new FormControl<boolean>(false),
    digitalAd: new FormControl<boolean>(false),
    promotion: new FormControl<boolean>(false),
    digitalAdClassic: new FormControl<boolean>(false)
  });


  constructor(private router: Router,
    private route: ActivatedRoute,
    private settingService: SettingsService,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog, private dialogRef: MatDialogRef<EditIntegrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.getDatById(this.data.id);
  }

  getDatById(id: any) {
    this.currentData = null;
    if (id) {
      this.integration$ = this.settingService.getIntegration(id).pipe(
        tap((int: any) => {
          if (int.Subscriptions) {
            const subscriptions = int.Subscriptions.split(',');
            if (subscriptions && subscriptions.length > 0) {
              for (let index = 0; index <= subscriptions.length - 1; index++) {
                if (subscriptions[index].trim() == 'offer') {
                  int.offer = true;
                }
                if (subscriptions[index].trim() == 'digitalAd') {
                  int.digitalAd = true;
                }
                if (subscriptions[index].trim() == 'promotion') {
                  int.promotion = true;
                }
                if (subscriptions[index].trim() == 'digitalAdClassic') {
                  int.digitalAdClassic = true;
                }
              }
            }
          }
          this.formGroup.patchValue(int as any);
          this.formGroup.markAllAsTouched();
          this.currentData = int;
        })
      );
    }
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }

  save() {
    if (!this.currentData) return;
    this.trimControlValues(this.formGroup);
    if (this.formGroup.valid) {
      const currentValue = this.formGroup?.value as any;
      currentValue.Subscriptions = '';
      if (currentValue.offer) {
        currentValue.Subscriptions += "offer, ";
      }
      if (currentValue.digitalAd) {
        currentValue.Subscriptions += "digitalAd, ";
      }
      if (currentValue.promotion) {
        currentValue.Subscriptions += "promotion, ";
      }
      if (currentValue.digitalAdClassic) {
        currentValue.Subscriptions += "digitalAdClassic";
      }
      const intData = {
        Id: this.currentData.Id,
        ClientKey: this.currentData.ClientKey,
        IntegrationName: this.currentData.IntegrationName,
        Status: this.currentData.Status,
        ClientId: currentValue.ClientId,
        AIClientKey: currentValue.AIClientKey,
        Subscriptions: currentValue.Subscriptions,
        OrganizationName: currentValue.OrganizationName,
        Endpoint: currentValue.Endpoint,
        TemplateId:  currentValue.TemplateId,
      }
      this.settingService.updateIntegration(intData, this.data.id).subscribe({
        next: (response) => {
          this.matSnackBar.open(
            `Integration saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.dialogRef.close(true);
        },
        error: (error) => {
          if (error.status === 400) {
            const apiValidations: any = error.error;
            if (Array.isArray(apiValidations)) {
              apiValidations.forEach((validation: any) => {
                if (this.formGroup?.get(validation.PropertyName)) {
                  const control = this.formGroup?.get(validation.PropertyName);
                  if (control) {
                    control.markAsTouched();
                    if (validation.PropertyName != 'Detail.ClientKey') {
                      control.setErrors({ invalid: validation.ErrorMessage });
                    }
                    this.matSnackBar.open(validation.ErrorMessage, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                  }
                }
              });
            } else {
              this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
            }
          }
        }
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
