<ng-container *ngIf="viewModel$ | async as viewModel else loading">
    <table *ngIf="viewModel.offers.length" mat-table [dataSource]="viewModel.offers">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="toggleSelectAll($event.checked)" [checked]="isAllSelected()"
                    [indeterminate]="isSomeSelectedOnPage() && !isAllSelected()"></mat-checkbox>
            </th>
            <td class="no-ellipsis" mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleSelect($event.checked, row)"
                    [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!--  Image Column -->
        <ng-container matColumnDef="Preview">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" style="width:100px">Preview</th>
            <td mat-cell *matCellDef="let record" class="mat-column-Image">
                <img mat-card-md-image
                    [src]="record?.FacingAsset ? utilityService.getOfferPreviewUrl(record?.FacingAsset, PreviewMode.Thumbnail) : './assets/images/default-image.png'"
                    alt="Offer image" (error)="onImageError($event)">
            </td>
        </ng-container>

        <!-- Headline Column -->
        <ng-container matColumnDef="Headline">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Headline</th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.Headline }}</td>
        </ng-container>

        <!-- BodyCopy Column -->
        <ng-container matColumnDef="BodyCopy">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Body Copy</th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.BodyCopy }}</td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="Status">
            <th style="width:120px" mat-header-cell *matHeaderCellDef [resizeColumn]="true">Status</th>
            <td mat-cell *matCellDef="let record">
                <div class="offer-status column-status">
                    <span class="ellipsis-3-line {{ getStatusClass(record) }}">{{ getStatusText(record) }}</span>
                </div>
            </td>
        </ng-container>

        <!-- Rank Column -->
        <ng-container matColumnDef="Rank">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Rank</th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.Rank }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <table *ngIf="!viewModel.offers.length">
        <tr>
            <td class="no-records" [attr.colspan]="displayedColumns.length">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>

    <!-- pager -->
    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.offers.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex" class="clone-paginator">
    </mat-paginator>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>