import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Offer, OfferDomain, OfferGroupDomain, OfferService, ViewMode } from '../..';
import { PageEvent } from '@angular/material/paginator';
import { OrganizationService, PreviewMode, UtilityService } from 'src/app/@shared';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatTableDataSource } from '@angular/material/table';

export interface OfferModelData {
  eventId: string,
  offerGroupId: string,
}

const DEFAULT_SEARCH_PLACEHOLDER = 'Search Offers';

@Component({
  selector: 'app-offer-group-offers-search-dialog',
  templateUrl: './offer-group-offers-search-dialog.component.html',
  styleUrls: ['./offer-group-offers-search-dialog.component.scss']
})
export class OfferGroupOffersSearchDialogComponent {

  @Input() offerIds: string[] = [];
  @Input() searchPlaceholder: string = DEFAULT_SEARCH_PLACEHOLDER;
  @Output() onSelection: EventEmitter<string[]> = new EventEmitter();

  modelData!: OfferModelData;
  ViewMode = ViewMode;
  defaultViewMode = ViewMode.Table;
  offersListData: MatTableDataSource<OfferDomain<Offer>> = new MatTableDataSource();

  searchQuery: string = '';
  isSelectionChanged: boolean = false;
  offerGroupOfferIds: string[] = [];
  offerGroup: OfferGroupDomain<Offer> | undefined;

  selection = new SelectionModel<any>(true, [], true, (o1, o2) => o1.DomainId === o2.DomainId);
  displayedColumns = ['select', 'Image', 'Headline', 'BodyCopy', 'Status', 'Rank'];
  flexMediaWatcher!: Subscription;

  assetsUrl: string = '';

  viewModel$ = combineLatest([
    this.offerService.offerGroupOffers$,
    this.offerService.offGroupOfferstotalRecords$,
    this.offerService.isLoading$,
    this.offerService.offerGroupPage$,
    this.offerService.viewMode$,
  ]).pipe(
    map(([offers, totalRecords, isLoading, page, viewMode]) => {
      this.offersListData = new MatTableDataSource(offers);
      return { offers, totalRecords, isLoading, page, viewMode };
    })
  );

  constructor(
    private mediaObserver: MediaObserver,
    public utilityService: UtilityService,
    private organizationService: OrganizationService,
    private dialogRef: MatDialogRef<OfferGroupOffersSearchDialogComponent>,
    public offerService: OfferService<Offer, OfferDomain<Offer>>,
    @Inject(MAT_DIALOG_DATA) public data: OfferModelData
  ) {
    this.modelData = data;
  }

  async ngOnInit() {
    this.detectViewportSizeChange();
    if (this.modelData.offerGroupId && this.modelData.eventId) {
      this.offerService.eventId = this.modelData.eventId;
      this.offerService.getOfferGroup(this.modelData.offerGroupId).subscribe((data) => {
        this.offerGroup = data;
        this.offerGroupOfferIds = (this.offerGroup?.OfferIds || []).filter((id): id is string => id !== null);
        this.offerGroupSearch();
      });
    }

    this.selection.changed.subscribe(changeObj => {
      var selectedOfferIds = changeObj.source.selected.map(offer => offer.DomainId);
      this.isSelectionChanged = selectedOfferIds.length ? !this.areOfferIdsEqual(selectedOfferIds, this.offerIds) : false;
    })

    this.organizationService.assetsUrl$.subscribe((res) => {
      this.assetsUrl = res;
    })
  }

  detectViewportSizeChange() {
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'Headline'];
        } else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'Headline'];
        } else {
          this.displayedColumns = ['select', 'Image', 'Headline', 'BodyCopy', 'Status', 'Rank'];
        }
      });
  }

  offerGroupSearch() {
    let versionIds = (this.offerGroup?.VersionIds || []).filter((id): id is string => id !== null);
    let tagIds = (this.offerGroup?.OfferTagIds || []).filter((id): id is string => id !== null);
    let search = this.searchQuery ? this.searchQuery : '';
    this.offerService.offerGroupSearch(versionIds, tagIds, search);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  toggleView(mode: ViewMode) {
    this.defaultViewMode = mode;
  }

  areOfferIdsEqual(selectedOfferIds: string[], offerIds: string[]): boolean {
    if (selectedOfferIds.length !== offerIds.length) {
      return false;
    }

    const sortedSelectedIds = [...selectedOfferIds].sort();
    const sortedOfferIds = [...offerIds].sort();

    return sortedSelectedIds.every((id, index) => id === sortedOfferIds[index]);
  }

  onSelect() {
    const offerIds: string[] = this.selection.selected.map(offer => offer.DomainId);
    const filteredOfferIds = offerIds.filter(id => !this.offerIds.includes(id));
    this.onSelection.emit(filteredOfferIds);
    this.closeDialog();
  }

  onSearch(): void {
    this.offerGroupSearch();
  }

  clearSearch(searchInput: HTMLInputElement): void {
    searchInput.value = '';
    this.searchQuery = '';
    this.offerGroupSearch();
  }

  onSort(sortState: Sort): void {
    this.offerService.offerGroupSort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.offerService.offerGroupPage(pageEvent);
  }

  isAllSelected(): boolean {
    return this.offersListData.data.every(offer => this.selection.isSelected(offer));
  }

  toggleSelect(checked: boolean, offer: OfferDomain<Offer>) {
    if (checked) {
      if (!this.selection.isSelected(offer)) {
        this.selection.select(offer);
      }
    } else {
      this.selection.deselect(offer);
    }
  }

  toggleSelectAll(checked: boolean): void {
    if (checked) {
      this.offersListData.data.forEach(offer => {
        if (!this.selection.isSelected(offer)) {
          this.selection.select(offer);
        }
      });
    } else {
      this.offersListData.data.forEach(offer => {
        !this.isOfferSelected(offer) && this.selection.deselect(offer);
      });
    }
  }

  isOfferSelected(offer: OfferDomain<Offer>): boolean {
    return this.offerGroupOfferIds.some(OfferGroupId => OfferGroupId === offer.Detail.Id);
  }

  isSomeSelectedOnPage(): boolean {
    return this.offersListData.data.some(offer => this.selection.isSelected(offer));
  }

  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = './assets/images/default-image.png';
  }

  getImageSrc(offer: OfferDomain<Offer>) {
    let imagesrc = '';
    let type = offer.FacingAsset?.FileType || (offer as any).Type || '';
    let fileName = offer.FacingAsset?.FileName || (offer as any).FileName || '';
    if (type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    } else if (this.assetsUrl) {
      imagesrc = this.assetsUrl + '/' + fileName;
    } else {
      imagesrc = './assets/images/default-image.png';
    }
    return imagesrc;
  }

  ngOnDestroy() {
    this.offerService.resetpage();
  }

}
