import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, distinctUntilChanged, map, Observable, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Filter, Logger, ThemeService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { OfferTag, OfferTagService } from '../..';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { MatSidenav } from '@angular/material/sidenav';
const log = new Logger('OfferTagsComponent');
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
@Component({
  selector: 'app-offer-tags',
  templateUrl: './offer-tags.component.html',
  styleUrls: ['./offer-tags.component.scss']
})
export class OfferTagsComponent<T extends OfferTag> implements OnInit {

  public get offerTagService(): OfferTagService<T> {
    return this._offerTagService;
  }
  public set offerTagService(value: OfferTagService<T>) {
    this._offerTagService = value;
  }
  viewModel$ = combineLatest([
    this.offerTagService.offerTagsIncludedExpired$,
    this.offerTagService.isLoading$,
    this.offerTagService.tagsTotalRecordsIncludedExpired$,
    this.offerTagService.filters$,
    this.offerTagService.page$,
  ]).pipe(
    map(([offerTags, isLoading, totalRecords, filters, page]) => {
      this.offerTagList = offerTags;
      offerTags.map((item) => {
        this.checkExpiredOfferTag(item);
      });
      if (offerTags.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.offerTagService.page(page);
        this.offerTagService.reload();
      }
      return { offerTags, isLoading, totalRecords, filters, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'OfferTagName', 'Title', 'StartDate', 'EndDate', 'ClientKey', 'Actions'];
  offerTagList: OfferTag[] = [];
  selection = new SelectionModel<OfferTag>(true, []);
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  filtersForm = new FormGroup({
    offertags: new FormControl<any[]>([], { nonNullable: true }),
    endDate: new FormControl<string | null>(null),
    search: new FormControl<string | null>(null),
    startDate: new FormControl<string | null>(null)
  });
  constructor(private _offerTagService: OfferTagService<T>, private mediaObserver: MediaObserver, private route: ActivatedRoute, private dialog: MatDialog,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.route.queryParams.subscribe(queryParams => {
      let searchKey = queryParams['search'];

      if (searchKey) {
        this._offerTagService.search(searchKey)
        this.filtersForm.controls.search.setValue(searchKey);
      } else {
        this._offerTagService.search('');
        this.filtersForm.controls.search.setValue('');
      }
    });

    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'OfferTagName', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'OfferTagName', 'Title', 'StartDate', 'EndDate', 'ClientKey', 'Actions'];
        }
      });
  }

  checkExpiredOfferTag(item: any) {
    dayjs.extend(isSameOrBefore)
    if (dayjs(item.StartDate).isSame(dayjs('01/01/0001'))) {
      item['StartDate'] = "";
    }
    if (dayjs(item.EndDate).isSame(dayjs('01/01/0001'))) {
      item['EndDate'] = "";
    }
    if (!dayjs(item.EndDate).isSame(dayjs('01/01/0001')) && item.EndDate != "" && dayjs(item.EndDate).isSameOrBefore(dayjs(new Date()).format('MM/DD/YYYY'))) {
      item['Expired'] = true;
    } else {
      item['Expired'] = false;
    }
  }
  onSearch(event: any) {
    this.offerTagService.search(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.offerTagService.search('');
  }
  removeFilter(filter: any) {
    if (filter.fieldName === 'offertags') {
      ///TODO: fix below
      this.filtersForm.patchValue({
        offertags: this.filtersForm.controls.offertags.value.filter(
          (item: any) => item.Id !== filter.value
        ),
      });
    }
    this.offerTagService.removeFilter(filter)
  }
  clearFilters() {
    this.filtersForm.reset();
    this.offerTagService.clearFilters();
  }
  resetFilters() {
    this.filtersForm.reset();
    this.offerTagService.search('');
    this.offerTagService.clearFilters();
    //this.sidenav.toggle();
  }
  onFilterChange(event: any) {
    switch (event.source.ngControl.name.toLowerCase()) {
      case 'offertags':
        // get the selected offer Tags 
        const values: any[] = event.value;
        // remove any existing offer Tags filters
        this.offerTagService.removeFilterByFieldName('offertags');
        // init the array of offer Tags filters
        const offerTagFilters: Filter[] = [];
        // loop through the selected offer Tags and add each as a filter
        values.forEach((value) => {
          // init the new filter
          const offerFilter: Filter = {
            displayText: value.OfferTagName,
            fieldName: 'offertags',
            value: value.Id,
          };
          // add the filter to the list of filters
          offerTagFilters.push(offerFilter);
        });
        // add the filters to the event service
        this.offerTagService.addFilters(offerTagFilters);
        break;
    }
  }

  onSort(sortState: Sort): void {
    this.offerTagService.sort(sortState);
    this.selection.clear();
  }
  onDateRangeChange() {
    let startDate = dayjs(this.filtersForm.controls.startDate.value).format();
    let endDate = dayjs(this.filtersForm.controls.endDate.value).format();
    let isbefore = dayjs(startDate).isBefore(endDate);
    let isSame = dayjs(startDate).isSame(endDate);

    if (startDate === 'Invalid Date' && endDate === 'Invalid Date') {
      this.offerTagService.dateRange('', '');
      return;
    }

    if (startDate === 'Invalid Date') {
      this.offerTagService.dateRange('', endDate);
    } else if (endDate === 'Invalid Date') {
      this.offerTagService.dateRange(startDate, '');
    } else if (this.filtersForm.valid) {
      this.offerTagService.dateRange(startDate, endDate);
    }
  }
  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.offerTagService.page(pageEvent);
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.offerTagList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.offerTagList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  deleteSelectedRecords() {
    let selectedRecords: OfferTag[];
    selectedRecords = this.getSelectedSectionRecords();
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Offer Tag(s)?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            let offertagIds: string[] = selectedRecords.map((item) => item.Id)
              .filter((item): item is string => typeof item === 'string' && item.trim().length > 0);

            this.offerTagService.deleteOfferTags(offertagIds).subscribe({
              next: () => {
                this.matSnackBar.open(`Offer Tag(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.offerTagService.reload();
                this.clearSelection();
              },
              error: (error) => {
                log.error('Error in deleting Offer Tag', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  downloadJSON() {
    this.offerTagService.downloadOfferTagsJSON();
  }

  downloadCSV() {
    this.offerTagService.downloadOfferTagsCSV();
  }

}
