import { AssetDetail, IOfferVariantViewModel, OfferPromo } from "..";

export interface PromoDomain<TPromoDetail extends OfferPromo> {
    Detail: TPromoDetail;
    ParentId?: string;
    DomainId?: string;

    // Association related fields for edit/create
    EventId?: string;
    BadgeAssetId?: string;
    OfferTagIds: Array<string | null>;
    VersionIds: Array<string | null>;

    // Fields for list or details
    EventOfferType?: string;
    FacingAsset?: AssetDetail;
    OfferTagNames?: string;
    VersionNames?: string;
    PricingPreviewHtml?: string;
    Variants: IOfferVariantViewModel[];
    VariantsCount: number;
    CommentsCount: number;
}