import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler, RouteReusableStrategy, SharedModule } from './@shared';
import { RouteReuseStrategy } from '@angular/router';
import { ShellModule } from './modules/shell';
import { HomeModule } from './modules/home/home.module';
import { SpinnerOverlayInterceptor } from './@shared/interceptors/spinner-overlay.interceptor';
import { StandardV1Module } from './modules/standard/v1/standard.v1.module';
import { RiteaidV1Module } from './modules/organizations/riteaid/v1/riteaid.v1.module';
import { WalgreensV1Module } from './modules/organizations/walgreens/v1/walgreens.v1.module';
import { DemoV1Module } from './modules/organizations/demo/v1/demo.v1.module';
import { QaAutomationV1Module } from './modules/organizations/qaautomation/v1/qaautomation.v1.module';
import { UnfiV1Module } from './modules/organizations/unfi/v1/unfi.v1.module';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { KrogerV1Module } from './modules/organizations/kroger/v1/kroger.v1.module';
import { debug } from 'console';
import { dematerialize } from 'rxjs';
import { CustomAuthHttpInterceptor } from './@shared/interceptors/custom-authHttpInterceptor';
import { ShopifyJwtInterceptor } from './@shared/interceptors/shopify-jwt-interceptor';
import { ShopifyV1Module } from './modules/organizations/shopify/v1/shopify.v1.module';
import { ARGV1Module } from './modules/organizations/arg/v1/arg.v1.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    ShellModule,
    HomeModule,
    StandardV1Module,
    KrogerV1Module,
    RiteaidV1Module,
    WalgreensV1Module,
    DemoV1Module,
    QaAutomationV1Module,
    UnfiV1Module,
    ShopifyV1Module,
    ARGV1Module,
    AppRoutingModule, // must be imported as the last module in the case where we use a fallback route
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
      "organization": env.auth.organization,
      "scope" : "openid profile email"
    }),
  ],
  providers: [
    AuthHttpInterceptor,
    ShopifyJwtInterceptor,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RouteReuseStrategy, useClass: RouteReusableStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerOverlayInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomAuthHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})

export class AppModule { }
