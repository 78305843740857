<form *ngIf="user$ | async as T; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">
            <div formGroupName="Detail">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="20px">
                    <div fxFlex="30" fxFlex.lt-md="100">
                        <div fxLayoutAlign="center center" class="offer-image">
                            <img mat-card-md-image [src]="imageUrl" alt="user Image">
                        </div>
                    </div>
                    <div fxFlex="70" fxFlex.lt-md="100">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="start start">
                            <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
                                <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                                    <mat-label>Organization Name</mat-label>
                                    <input matInput type="text" formControlName="organizationName" />
                                    <mat-error
                                        *ngIf="formGroup.controls.Detail.controls.organizationName.hasError('required')">
                                        Organization Name is required
                                    </mat-error>
                                    <mat-error
                                        *ngIf="formGroup.controls.Detail.controls.organizationName.hasError('invalid')">
                                        {{formGroup.controls.Detail.controls.organizationName.getError('invalid')}}
                                    </mat-error>
                                    <mat-error
                                        *ngIf="formGroup.controls.Detail.controls.organizationName.status=='INVALID'">
                                        {{formGroup.controls.Detail.controls.organizationName.errors!['maxlength'] &&
                                        'Maximum
                                        length can be' +
                                        formGroup.controls.Detail.controls.organizationName.errors!['maxlength']['requiredLength']
                                        + '.'}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="start start">
                            <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
                                <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                                    <mat-label>Account Owner</mat-label>
                                    <input matInput type="text" formControlName="accountOwner" />
                                    <mat-error
                                        *ngIf="formGroup.controls.Detail.controls.accountOwner.hasError('required')">
                                        Account Owner is required
                                    </mat-error>
                                    <mat-error
                                        *ngIf="formGroup.controls.Detail.controls.accountOwner.hasError('invalid')">
                                        {{formGroup.controls.Detail.controls.accountOwner.getError('invalid')}}
                                    </mat-error>
                                    <mat-error
                                        *ngIf="formGroup.controls.Detail.controls.accountOwner.status=='INVALID'">
                                        {{formGroup.controls.Detail.controls.accountOwner.errors!['maxlength'] &&
                                        'Maximum
                                        length can be' +
                                        formGroup.controls.Detail.controls.accountOwner.errors!['maxlength']['requiredLength']
                                        +
                                        '.'}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="start start">
                            <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
                                <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                                    <mat-label>Account Admin - Primary</mat-label>
                                    <input matInput type="text" formControlName="accountAdmin" />
                                    <mat-error
                                        *ngIf="formGroup.controls.Detail.controls.accountAdmin.hasError('required')">
                                        Account Admin - Primary is required
                                    </mat-error>
                                    <mat-error
                                        *ngIf="!formGroup.controls.Detail.controls.accountAdmin.hasError('required') && formGroup.controls.Detail.controls.accountAdmin.status=='INVALID'">
                                        Please enter valid Email
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <ng-container *ngIf="viewModel$ | async as viewModel">
        <div class="users-table">
            <table mat-table [dataSource]="viewModel.users" class="mat-elevation-z8 user-management-table">
                <!-- Name Column -->
                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef>First Name</th>
                    <td mat-cell *matCellDef="let element">{{ element.Detail.FirstName }}</td>
                </ng-container>

                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef>Last Name</th>
                    <td mat-cell *matCellDef="let element">{{ element.Detail.LastName }}</td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element">{{ element.Detail.Email }}</td>
                </ng-container>
                <!-- Edit Link Column -->
                <!-- <ng-container matColumnDef="edit_user">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px" class="user-table-actions">
                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="editUser(element.Detail)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item>
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete</span>
                                </button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="editUser(row.Detail)"></tr>
            </table>

            <table *ngIf="!viewModel.users.length">
                <tr>
                    <td class="no-records" [attr.colspan]="displayedColumns.length">
                        <h2>No Records Found</h2>
                    </td>
                </tr>
            </table>

            <!-- pager -->
            <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
                [pageSizeOptions]="[5, 10, 20, 50, 100,500]" *ngIf="viewModel.users.length" showFirstLastButtons
                [pageIndex]="viewModel.page.pageIndex">
            </mat-paginator>
        </div>
    </ng-container>

    <mat-card-actions class="card-actions">
        <button mat-button (click)="addUser()" style="width: 220px;">
            <mat-icon>add</mat-icon> Add Admin Users
        </button>
        <!-- <div fxLayout="row" fxLayoutAlign="end" class="mat-card-actions-details">
            <button mat-stroked-button type="reset" (click)="cancel()">
                Cancel
            </button>
            <button mat-raised-button type="button" (click)="save() " [disabled]="formGroup.invalid" color="accent">
                Save
            </button>
        </div> -->
    </mat-card-actions>
</form>
<input [hidden]="true" type="file" #fileInput id="fileInput" name="fileInput" (change)="selectFiles($event)"
    title="upload file upto 5MB" multiple accept="*" />
<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>