<ng-container *ngFor="let integration of integrations">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h2>{{integration.IntegrationName}}</h2>
        <mat-slide-toggle [checked]="integration.Status" (change)="updateStatus($event, integration)"></mat-slide-toggle>
    </div>
    <div fxLayout="row" class="integrationItem" fxLayoutAlign="space-between start">
        <div *ngIf="integration.Status" fxLayout="row"><mat-icon class="activeItem">fiber_manual_record</mat-icon>
            Active</div>
        <div *ngIf="!integration.Status" fxLayout="row"><mat-icon class="inactiveItem">fiber_manual_record</mat-icon> In
            Active</div>
        <button *ngIf="integration.Status" mat-button (click)="openDialog(integration)">Edit IA Settings</button>
    </div>
</ng-container>