import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/@shared';
import { ARGV1RoutingModule } from './arg.v1.routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    SharedModule,
    ARGV1RoutingModule
  ]
})

export class ARGV1Module { }
