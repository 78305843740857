<!-- actions bar -->
<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar" style="height: 50px;">
  <button style="margin-left: 10px !important;" class="file-btn" mat-button [matMenuTriggerFor]="menu">File</button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>New Design File</button>
    <hr class="mat-divider mat-divider-horizontal">
    <button mat-menu-item (click)="saveUniqueMontage()">Save As Unique Montage</button>
    <button mat-menu-item (click)="onDownload()">Download</button>
    <hr class="mat-divider mat-divider-horizontal">
    <button mat-menu-item (click)="showVersionHistory()">Show Version History</button>
  </mat-menu>
  <span class="menu-spacer"></span>
  <div class="zoom-option">
    <label for="" class="label-title">Zoom</label>
    <input type="range" class="float-right" step="0.01" min="0.1" max="1" [(ngModel)]="viewScale" />

    <label for="range" id="value"></label>
  </div>
  <button class="btn btn-primary" style="margin-right: 10px !important;" (click)="close()">Close</button>
  <button class="btn btn-primary" mat-raised-button color="accent" style="margin-right: 10px !important;"
    (click)="saveMontageConfig()">Save</button>
</div>
<div class="main">
  <div class="main_sidebar">
    <div>
      <span [ngClass]="{'active': layoutSelected == 'Layout' }" style="    display: grid;
      grid-template-rows: 50% 50%;
      grid-gap: 10p;
      justify-content: center;
      padding: 25px;" (click)="layoutDesign('Layout')"><span style="    margin-left: 15px;
    margin-right: 5px;"><svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.78125 1.15625C8.26042 0.947917 8.75 0.947917 9.25 1.15625L16.0625 4.3125C16.3333 4.45833 16.4792 4.6875 16.5 5C16.4792 5.3125 16.3333 5.54167 16.0625 5.6875L9.21875 8.84375C8.73958 9.05208 8.25 9.05208 7.75 8.84375L0.9375 5.6875C0.666667 5.54167 0.520833 5.3125 0.5 5C0.520833 4.6875 0.666667 4.45833 0.9375 4.3125L7.78125 1.15625ZM14.4062 7.5625L16.0625 8.3125C16.3333 8.45833 16.4792 8.6875 16.5 9C16.4792 9.3125 16.3333 9.54167 16.0625 9.6875L9.21875 12.8438C8.73958 13.0521 8.25 13.0521 7.75 12.8438L0.9375 9.6875C0.666667 9.54167 0.520833 9.3125 0.5 9C0.520833 8.6875 0.666667 8.45833 0.9375 8.3125L2.59375 7.5625L7.34375 9.75C8.11458 10.0833 8.88542 10.0833 9.65625 9.75L14.4062 7.5625ZM9.65625 13.75L14.4062 11.5625L16.0625 12.3125C16.3333 12.4583 16.4792 12.6875 16.5 13C16.4792 13.3125 16.3333 13.5417 16.0625 13.6875L9.21875 16.8438C8.73958 17.0521 8.25 17.0521 7.75 16.8438L0.9375 13.6875C0.666667 13.5417 0.520833 13.3125 0.5 13C0.520833 12.6875 0.666667 12.4583 0.9375 12.3125L2.59375 11.5625L7.34375 13.75C8.11458 14.0833 8.88542 14.0833 9.65625 13.75Z"
              fill="white" />
          </svg>
        </span>
        <div style="    margin-left: 5px;margin-top: 5px;">Layout</div>
      </span>
    </div>
  </div>

  <div class="left-section">
    <div class="section">

      <div class="layout-section" *ngIf="layoutSelected=='Layout'">
        <div class="layers-section">
        <div class="layer-section bold"> Layers</div>
        <div class="layer-image">
          <app-image-layers [montageImages]="montageImages" [imagesConfigs]="imagesConfigs" (onChange)="onReorder()"
            (onDelete)="onDelete($event)"></app-image-layers>
        </div>
        <button type="button" style="width: 95% !important;" mat-raised-button color="accent"
          matTooltip="Add new Assets" data-testid="New Asset" (click)="openSearchDialog()">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add Assets
        </button>
      </div>
        <div class="offer-details">
          <div class="offer-header-details">
            <p>Offer Details</p>
          </div>
          <div class="offer-body-details">
            <div class="headline-details">
              <div class="title">Headline</div>
              <div class="content">{{offerDetails.Detail?.Headline}}</div>
            </div>
            <div class="body-copy-details">
              <div class="title">Body Copy</div>
              <div class="content">{{offerDetails.Detail?.BodyCopy}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="right-section" id="right-section">
    <div class="konvajs-wrap" [style.scale]="viewScale" style="justify-content: center; transform-origin: top">
      <div id="container"></div>
    </div>
  </div>
</div>