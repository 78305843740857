import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';
import { Offer, OfferGroupDomain, OfferPromo, OfferTagsResponseModel, PromoDomain } from '..';
import { StatusCount } from '../models/status-count.model';
import { SelectionModel } from '@angular/cdk/collections';
import { CloneType } from '../models/clone-type.model';

@Injectable({
  providedIn: 'root',
})
export class OfferPromoService<TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>> {
  private saveorCancel = new BehaviorSubject<string>('');

  // initialize behavior subjects
  private eventIdBehaviorSubject = new BehaviorSubject<string>('');
  private offerIdBehaviorSubject = new BehaviorSubject<string>('');
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  public loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  public commentDialogBoxStatusBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private uploadPromosPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private uploadPromosSearchBehaviorSubject = new BehaviorSubject<string>('');
  private uploadPromosSortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc', });
  private sortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc', });
  private childVersionsSortBehaviorSubject = new BehaviorSubject({ active: 'Name', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private totalRecordsCount = new BehaviorSubject<number>(0);
  private totalPromoRecordsCount = new BehaviorSubject<number>(0);
  private statusUpdated = new BehaviorSubject<Date>(new Date());
  private offerDetailsReadOnlyMode = new BehaviorSubject<any>(null);
  private cloneEventIdBehaviorSubject = new BehaviorSubject<string>('');
  private cloneSearchBehaviorSubject = new BehaviorSubject<string>('');
  private cloneEntityTypeBehaviorSubject = new BehaviorSubject<string>(CloneType.EVENT);
  private cloneOffersPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private cloneOffersTotalRecordsCount = new BehaviorSubject<number>(0);
  private offerGrpPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private offerGrpSearchBehaviorSubject = new BehaviorSubject<string>('');
  private offerGrpSortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc', });
  private offerGrpReloadBehaviorSubject = new BehaviorSubject<string>('');
  private offerGrptotalRecordsCount = new BehaviorSubject<number>(0);
  private offerGrpviewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');

  // we do not wish to expose our behavior subjects.  create public observables
  public commentDialogBoxStatus$ = this.commentDialogBoxStatusBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public uploadPromosPage$ = this.uploadPromosPageBehaviorSubject.asObservable();
  public uploadPromosSearch$ = this.uploadPromosSearchBehaviorSubject.asObservable();
  public uploadPromosSort$ = this.uploadPromosSortBehaviorSubject.asObservable();
  public childVersionsSort$ = this.childVersionsSortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public eventId$ = this.eventIdBehaviorSubject.asObservable();
  public offerId$ = this.offerIdBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public saveorCancel$ = this.saveorCancel.asObservable();
  public totalRecordsCount$ = this.totalRecordsCount.asObservable();
  public totalPromoRecordsCount$ = this.totalPromoRecordsCount.asObservable();
  public statusUpdated$ = this.statusUpdated.asObservable();
  public selection = new SelectionModel<OfferPromo>(true, []);
  public offerDetailsReadOnlyMode$ = this.offerDetailsReadOnlyMode.asObservable();
  public selectedFilterItems: any;
  public cloneEventId$ = this.cloneEventIdBehaviorSubject.asObservable();
  public cloneSearch$ = this.cloneSearchBehaviorSubject.asObservable();
  public cloneEntityType$ = this.cloneEntityTypeBehaviorSubject.asObservable();
  public cloneOffersPage$ = this.cloneOffersPageBehaviorSubject.asObservable();
  public cloneOffersTotalRecordsCount$ = this.cloneOffersTotalRecordsCount.asObservable();
  public offerGrpPage$ = this.offerGrpPageBehaviorSubject.asObservable();
  public offerGrpSearch$ = this.offerGrpSearchBehaviorSubject.asObservable();
  public offerGrpSort$ = this.offerGrpSortBehaviorSubject.asObservable();
  public offerGrptotalRecordsCount$ = this.offerGrptotalRecordsCount.asObservable();
  public offerGrpviewMode$ = this.offerGrpviewModeBehaviorSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private organizationService: OrganizationService
  ) { }

  // create the parameters observable that looks for changes in cloning modal.
  public cloneOfferPromosParams$ = combineLatest([
    this.cloneEventIdBehaviorSubject,
    this.cloneSearchBehaviorSubject.pipe(debounceTime(300)),
    this.cloneEntityTypeBehaviorSubject,
    this.cloneOffersPageBehaviorSubject
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    filter(([eventId, search, entityType, page]) => {
      //if the entityType is either OFFER or PROMO, pipe continues
      return (entityType === CloneType.OFFER || entityType === CloneType.PROMO)
    }),
    map(([eventId, search, entityType, page]) => {
      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          eventId: eventId,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $expand: 'Detail',
          $orderby: 'Detail/Rank asc',
          $count: true
        },
      });
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }
      return params;
    })
  )

  private galleyParams = new HttpParams();

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.eventIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([eventId, page, sort, search, filters, reload]) => {
      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'EventType') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          eventId: eventId,
          // mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $expand: 'Detail,Variants',
          $orderby: _orderby,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = this.buildFilterParam(filters, params);
      }
      this.galleyParams = params;
      return params;
    })
  );

  public uploadPromoParams$ = combineLatest([
    this.eventIdBehaviorSubject,
    this.uploadPromosPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.uploadPromosSortBehaviorSubject,
    this.uploadPromosSearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([eventId, page, sort, search, reload]) => {
      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'EventType') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          eventId: eventId,
          // mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $expand: 'Detail',
          $orderby: _orderby,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }
      return params;
    })
  );

  public promoVersionsparams$ = combineLatest([
    this.offerIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.childVersionsSortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([offerId, page, sort, search, filters, reload]) => {
      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          key: offerId,
          // mode: viewMode,
          // $skip: page.pageIndex * page.pageSize,
          // $top: page.pageSize,
          $expand: 'Detail',
          $orderby: `Detail/${sort.active} ${sort.direction}`,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public filterParams$ = combineLatest([
    this.eventIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([eventId, page, sort, search, filters, reload]) => {
      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          eventId: eventId,
          // mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $expand: 'Detail',
          $orderby: `Detail/${sort.active} ${sort.direction}`,
          $count: true,
          $filter: `EventType eq 'PROMO'`,
          types: `PROMO`,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = this.buildFilterParam(filters, params);
      }
      return params;
    })
  );

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public offerGrpparams$ = combineLatest([
    this.eventIdBehaviorSubject,
    this.offerIdBehaviorSubject,
    this.offerGrpPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.offerGrpSortBehaviorSubject,
    this.offerGrpSearchBehaviorSubject.pipe(debounceTime(300)),
    this.offerGrpReloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([eventId, offerId, page, sort, search, reload]) => {
      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'EventType') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          eventId: eventId,
          offerGroupId: offerId,
          // mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $expand: 'Detail,Variants',
          $orderby: _orderby,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  set eventId(eventId: string) {
    this.eventIdBehaviorSubject.next(eventId);
  }

  set offerId(offerId: string) {
    this.offerIdBehaviorSubject.next(offerId);
  }

  set cloneEventId(eventId: string) {
    this.cloneEventIdBehaviorSubject.next(eventId);
  }

  public setSaveorCancel(currentAction: string) {
    this.saveorCancel.next(currentAction);
  }

  public updateRecordStatus(date: Date) {
    this.statusUpdated.next(date);
  }

  // get the offers by eventId
  private offerPromoResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/GetByEventId`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // get the offers by eventId
  private cloneOfferPromosResponse$ = this.cloneOfferPromosParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) => {
      let entityEndpoint = this.cloneEntityTypeBehaviorSubject.value === CloneType.OFFER ? 'OfferDomains/GetByEventId' : 'PromoDomains/GetByEventId';
      return this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/${entityEndpoint}`,
        { params: _params }
      )
    }),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // get the promos by eventId
  private uploadPromosResponse$ = this.uploadPromoParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/GetByEventId`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // get the offers by eventId
  private offersPromosResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/GetOffersAndPromosByEventId`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  private searchAllEventOfferTypesByEventId$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferGroupDomains/SearchAllEventOfferTypesByEventId`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  private typeIdsByEventInitialData: any = null;

  setTypeIdsByEventInitialData(res: any): void {
    let pageData = this.pageBehaviorSubject.value;
    let totalRecords = res ? res['@odata.count'] : null;
    let data = null;

    if (res?.value?.length && totalRecords) {
      data = new Array(totalRecords).fill(null);
      data.splice(pageData.pageIndex * pageData.pageSize, res.value.length, ...res.value.map(this.transformOfferToVariantList));
    }

    this.typeIdsByEventInitialData = data;
  }

  transformOfferToVariantList(offer: any): any {
    return {
      BaseId: offer.DomainId,
      Label: offer.EventOfferType.charAt(0).toUpperCase() + offer.EventOfferType.slice(1).toLowerCase(),
      VariantIds: offer.Variants.map((variant: any) => variant.Id),
    };
  }

  getTypeIdsByEventInitialData(): any {
    return this.typeIdsByEventInitialData;
  }

  searchAllEventOfferTypeIdsByEvent(page: any): Observable<any> {
    const sort = this.sortBehaviorSubject.value;
    const search = this.searchBehaviorSubject.value;
    const filters = this.filterBehaviorSubject.value;

    let params = new HttpParams({
      fromObject: {
        eventId: this.eventIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $expand: 'Variants',
        $orderby: `Detail/${sort.active} ${sort.direction}`,
        $count: true,
      },
    });

    if (search.length) {
      params = params.append('$search', `"${search}"`);
    }

    if (filters.length > 0) {
      params = this.buildFilterParam(filters, params);
    }

    return this.httpClient.get(
      `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferGroupDomains/SearchAllEventOfferTypeIdsByEvent`,
      { params }
    )
  }

  // offerpromo listing
  public offerPromos$: Observable<TPromoDomain[]> =
    this.offerPromoResponse$.pipe(
      map((res: any) => {
        this.totalRecordsCount.next(res['@odata.count']);
        return res.value;
      })
    );

  // offerpromo listing
  public cloneOfferPromos$: Observable<TPromoDomain[]> =
    this.cloneOfferPromosResponse$.pipe(
      map((res: any) => {
        this.cloneOffersTotalRecordsCount.next(res['@odata.count'])
        return res.value;
      })
    );

  // total number of offer records based on filtering
  public cloneOffersTotalRecords$: Observable<number> = this.cloneOffersTotalRecordsCount$.pipe(
    map((res: any) => res)
  );

  //promo listing
  public uploadPromos$: Observable<TPromoDomain[]> =
    this.uploadPromosResponse$.pipe(
      map((res: any) => {
        this.totalRecordsCount.next(res['@odata.count']);
        return res.value;
      })
    );

  // offerpromo listing
  public getAllEventOfferTypesByEventId$: Observable<OfferGroupDomain<Offer>[]> = this.searchAllEventOfferTypesByEventId$.pipe(
    map((res: any) => {
      this.setTypeIdsByEventInitialData(res);
      this.totalRecordsCount.next(res['@odata.count']);
      return res.value;
    })
  );

  // total number of offer records based on filtering
  public totalRecords$: Observable<number> = this.totalRecordsCount$.pipe(
    map((res: any) => res)
  );

  // get the child versions by offerid
  private promoVersionsResponse$ = this.promoVersionsparams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/GetVariants`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // offerpromo listing
  public getpromoChildVersions$: Observable<OfferGroupDomain<Offer>[]> =
    this.promoVersionsResponse$.pipe(map((res: any) => (res ? res.value : [])));

  // total number of offer records based on filtering
  public totalVersionsRecords$: Observable<number> =
    this.promoVersionsResponse$.pipe(
      map((res: any) => (res ? res['@odata.count'] : 0))
    );

  // gets an offer by id
  getPromo(promoId: string): Observable<PromoDomain<TOfferPromo>> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/${promoId}?$expand=Detail`;
    return this.httpClient.get<PromoDomain<TOfferPromo>>(url);
  }

  // gets an offer by id
  getOfferPromoTags(
    eventId: string,
    offers: string[]
  ): Observable<OfferTagsResponseModel> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/GetOfferTagsByOfferIds?eventId=${eventId}`;
    return this.httpClient.post<OfferTagsResponseModel>(url, offers);
  }

  massSaveofferTags(offerTags: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/UpdateOfferTagsByOfferIds`;
    return this.httpClient.post<TOfferPromo>(url, offerTags);
  }

  getPromoVersions(promoId: string): Observable<TOfferPromo> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/GetPromoVersions?promoId=${promoId}`;
    return this.httpClient.get<TOfferPromo>(url);
  }

  getStatusCount(): Observable<StatusCount> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/StatusCount?eventId=${this.eventIdBehaviorSubject.value}`;
    return this.httpClient.get<StatusCount>(url);
  }

  getVariantDetails(variantId: string, EventOfferType: string): Observable<OfferGroupDomain<Offer>> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/${EventOfferType === "OFFER" ? 'OfferDomains' : 'PromoDomains'}/GetPreview?key=${variantId}&$expand=Detail`;
    return this.httpClient.get<OfferGroupDomain<Offer>>(url);
  }

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  getPageDetails() {
    return this.pageBehaviorSubject.value;
  }

  cloneOffersPage(page: any) {
    this.cloneOffersPageBehaviorSubject.next(page);
  }

  // set the current page
  uploadPromosPage(page: any) {
    this.uploadPromosPageBehaviorSubject.next(page);
  }

  getAssets() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains?$expand=Detail&$filter=assetgroupname eq 'Badges'`;
    return this.httpClient.get(url);
  }

  savePromo(PromoDomain: PromoDomain<TOfferPromo>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/Post`;
    return this.httpClient.post(url, PromoDomain);
  }

  savePromoProperties(promoIds: string[], properties: any) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath
      }/${this.organizationService.organization?.version
      }/PromoDomains/UpdatePromoProperties?${this.preparePromoIdString(
        promoIds
      )}`;
    return this.httpClient.post(url, properties);
  }

  preparePromoIdString(promoIds: any) {
    let urlParams = '';
    if (promoIds && promoIds.length > 0) {
      for (let index = 0; index <= promoIds.length - 1; index++) {
        urlParams += `promoIds=${promoIds[index]}`;
        if (index != promoIds.length - 1) {
          urlParams += '&';
        }
      }
    }
    return urlParams;
  }

  // deletes an Offer by id
  deletePromo(id: string, eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/${id}?eventId=${eventId}`;
    return this.httpClient.delete(url);
  }

  // deletes an Offer by id
  deleteBasePromo(id: string, eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/DeletePromoVersions?key=${id}&eventId=${eventId}`;
    return this.httpClient.delete(url);
  }

  clonePromo(sourceId: string, PromoDomain: PromoDomain<TOfferPromo>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/ClonePromo?sourceId=${sourceId}`;
    return this.httpClient.post(url, PromoDomain);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the sort property and order
  uploadPromosSort(sort: any) {
    this.uploadPromosSortBehaviorSubject.next(sort);
  }

  childVersionsSort(sort: any) {
    this.childVersionsSortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  uploadPromosSearch(search: string) {
    const page = this.uploadPromosPageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.uploadPromosSearchBehaviorSubject.next(search);
    this.uploadPromosPageBehaviorSubject.next(page);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  cloneSearch(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.cloneSearchBehaviorSubject.next(search);
    this.cloneOffersPageBehaviorSubject.next(page);

  }

  resetClonePage() {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.cloneOffersPageBehaviorSubject.next(page);
  }

  cloneEntity(type: string) {
    this.cloneEntityTypeBehaviorSubject.next(type);
    this.resetClonePage();
  }


  // reloads/refreshes the offer listing
  reload() {
    // reload the Offer data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // changes the view mode of the offer listing
  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

  // changes the view mode of the offer listing
  toggleOfferGrpViewMode(mode: string) {
    this.offerGrpviewModeBehaviorSubject.next(mode);
  }

  // adds filters to the event listing
  addFilters(newFilters: Filter[]) {
    const filters = this.filterBehaviorSubject.value;

    newFilters.forEach((filter) => {
      if (
        filters.findIndex(
          (item) =>
            item.fieldName.toLowerCase() === filter.fieldName.toLowerCase() &&
            item.value.toLowerCase() === filter.value.toLowerCase()
        ) === -1
      ) {
        filters.push(filter);
      }
    });

    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the event listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(
      (item) => item !== filter
    );
    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the event listing
  removeFilterByFieldName(fieldName: string) {
    const filters = this.filterBehaviorSubject.value.filter(
      (item) => item.fieldName.toLowerCase() !== fieldName.toLowerCase()
    );
    this.filterBehaviorSubject.next(filters);
  }

  // removes all filters for the event listing
  clearFilters() {
    this.filterBehaviorSubject.next([]);
  }

  // builds the filter expressions for filtering the event listing
  private buildFilterParam(filters: Filter[], params: HttpParams): HttpParams {
    // build the offerTagId expression
    const offerTagIdFilter = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'offertagid'
    );

    // loop through the division id filters and add filter statement to param
    offerTagIdFilter.forEach((filter, index) => {
      params = params.append('offerTagIds', `${filter.value}`);
    });

    // build the versionIds expression
    const versionIdFilters = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'versionid'
    );
    // loop through the version id filters and add filter statement to param
    versionIdFilters.forEach((filter, index) => {
      params = params.append('versionIds', `${filter.value}`);
    });

    // build the notags expression
    const noTagFilters = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'notags'
    );
    // loop through the notags filters and add filter statement to param
    noTagFilters.forEach((filter, index) => {
      params = params.append('noTags', `${filter.value}`);
    });


    // build the notags expression
    const hasCommentsFilters = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'hascomments'
    );
    // loop through the notags filters and add filter statement to param
    hasCommentsFilters.forEach((filter, index) => {
      params = params.append('hasComments', `${filter.value}`);
    });
    // build the versionIds expression
    const eventIdFilters = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'eventtype'
    );
    // loop through the version id filters and add filter statement to param
    eventIdFilters.forEach((filter, index) => {
      params = params.append('types', `${filter.value}`);
    });

    // build the statusType expression
    const statusTypeFilters = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'status'
    );
    // loop through the status filters and add filter statement to param
    statusTypeFilters.forEach((filter, index) => {
      params = params.append('statuses', `${filter.value}`);
    });

    // build the pageIds expression
    const pageIdsFilters = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'pageids'
    );
    // loop through the status filters and add filter statement to param
    pageIdsFilters.forEach((filter, index) => {
      params = params.append('pageIds', `${filter.value}`);
    });

    return params;
  }

  updateStatus(status: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/UpdateStatus`;
    return this.httpClient.post(url, status);
  }

  deleteOfferPromos(promos: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/DeletePromos`;
    return this.httpClient.post(url, promos);
  }

  downloadOffersJSON(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Offers/EtlExport?eventId=${eventId}`;
    window.open(url, '_blank');
  }

  downloadPromosJSON(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Promos/EtlExport?eventId=${eventId}`;
    window.open(url, '_blank');
  }

  downloadEventJSON(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Events/EtlExportEvent?eventId=${eventId}`;
    window.open(url, '_blank');
  }

  downloadCSV(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Events/ExportOffers?eventId=${eventId}`;
    window.open(url, '_blank');
  }

  downloadCommentsCSV(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Events/ExportComments?eventId=${eventId}&timestamp=${Date.now()}`;
    window.open(url, '_blank');
  }

  setOfferDetailsReadOnly(val: boolean) {
    this.offerDetailsReadOnlyMode.next(val);
  }

  getOfferDetailsReadOnlyValue(): boolean {
    return this.offerDetailsReadOnlyMode.value;
  }

  getFilterSelections() {
    return this.selectedFilterItems;
  }

  setFilterSelections(items: any) {
    this.selectedFilterItems = items;
  }

  cloneOfferPromos(req: any) {
    const entityEndpoint = this.cloneEntityTypeBehaviorSubject.value === CloneType.OFFER ? 'CloneOffers' : 'ClonePromos';
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains/${entityEndpoint}`;
    return this.httpClient.post(url, req);
  }

  ExportSelectedGalleyProof(eventId: string, offerIds: string[]) {
    this.galleyParams = this.galleyParams.set('eventId', eventId);
    this.galleyParams = this.galleyParams.set('mode', this.viewModeBehaviorSubject.value.toLowerCase());
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains/ExportSelectedGalleyProof`;
    return this.httpClient.post(url, offerIds, { responseType: 'blob', params: this.galleyParams });
  }

  exportAllGalleyProof(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains/ExportAllGalleyProof?eventId=${eventId}&mode=${this.viewModeBehaviorSubject.value.toLowerCase()}`;
    return this.httpClient.post(url, {}, { responseType: 'blob' })
  }

  private offerGrpOffersResponse$ = this.offerGrpparams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/offerdomains/GetOffersByOfferGroupId`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // offerpromo listing
  public getofferGrpOffers$: Observable<TPromoDomain[]> = this.offerGrpOffersResponse$.pipe(
    map((res: any) => {
      this.offerGrptotalRecordsCount.next(res['@odata.count']);
      return res.value;
    })
  );

  // total number of offer records based on filtering
  public offerGrptotalRecords$: Observable<number> = this.offerGrptotalRecordsCount$.pipe(
    map((res: any) => res)
  );

  // set the current page
  offerGrpPage(page: any) {
    this.offerGrpPageBehaviorSubject.next(page);
  }

  // set the current page
  resetOfferGrpPage() {
    this.offerGrpPageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // sets the sort property and order
  offerGrpSort(sort: any) {
    this.offerGrpSortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  offerGrpSearch(search: string) {
    const page = this.offerGrpPageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.offerGrpSearchBehaviorSubject.next(search);
    this.offerGrpPageBehaviorSubject.next(page);
  }

  // reloads/refreshes the offer listing
  offerGrpReload() {
    // reload the Offer data
    this.offerGrpReloadBehaviorSubject.next(uuidv4());
  }

  createJAICreative(offerId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/JunctionAI/CreateJAICreative?key=${offerId}`;
    return this.httpClient.put(`${url}`, {});
  }

  getJAICreative(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/JunctionAI/GetCreativeDetails?key=${id}`;
    return this.httpClient.get(`${url}`);
  }

}
