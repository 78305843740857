import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, catchError, of } from 'rxjs';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { Event, EventService, Offer, OfferDomain, OfferService } from '../..';
import { OfferPromo } from 'src/app/modules/standard/v1';
import { PromoDomain } from 'src/app/modules/standard/v1';
import { OfferPromoService } from 'src/app/modules/standard/v1';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Status } from '../../models/offer-promo-status';
import { StatusCount } from '../../models/status-count.model';

const log = new Logger('OffersComponent');

@Component({
  selector: 'app-version-offers',
  templateUrl: './version-offers.component.html',
  styleUrls: ['./version-offers.component.scss']
})

export class VersionOffersComponent<TEvent extends Event, TOfferPromo extends OfferPromo, T extends Offer, TOfferDomain extends OfferDomain<Offer>, TPromoDomain extends PromoDomain<OfferPromo>> implements OnInit {
  Status: typeof Status = Status;
  public statusCount: StatusCount = {};
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild('offerPromoTable', { static: false }) offerPromoTable: any;
  @ViewChild('offerPromoCards', { static: false }) offerPromoCards: any;
  viewModel$ = combineLatest([
    this.eventService.getEvent(this.route.snapshot.params['eventId']),
    this.offerpromoService.isLoading$,
    this.offerpromoService.viewMode$,
    this.offerpromoService.eventId$,
  ]).pipe(
    map(([event, isLoading, viewMode, eventId]) => {
      return { event, isLoading, viewMode, eventId }
    }),
    catchError(error => {
      log.error('Error loading view model', error);
      return of({ event: null, isLoading: false, viewMode: null, eventId: null });
    })
  );

  constructor(
    private eventService: EventService<TEvent>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    public offerService: OfferService<T, TOfferDomain>,
  ) { }

  ngOnInit(): void {
    log.debug('init');
    this.offerService.offerId = this.route.snapshot.params['offerId'];
    this.offerpromoService.offerId = this.route.snapshot.params['offerId'];
  }

  backOffers() {
    this.router.navigate([`../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  getStatusCount() {
    this.offerpromoService.getStatusCount().subscribe((count) => {
      this.statusCount = count;
    })
  }

  toggleView(mode: string) {
    this.offerpromoService.toggleViewMode(mode);
  }

  deleteSelectedRecords() {
    let selectedRecordsDetails: any;
    if (this.offerPromoTable) {
      selectedRecordsDetails = this.offerPromoTable.getSelectedSectionRecords();
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = this.offerPromoCards.getSelectedSectionRecords();
    }
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove selected Offer/Promo?`,
        },
        disableClose: true,
      });
      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            const selectedRecords = selectedRecordsDetails.map((x: any) => { x.EventId = this.route.snapshot.params['eventId']; return x.Detail; });
            this.offerpromoService.deleteOfferPromos(selectedRecords).subscribe({
              next: () => {
                this.matSnackBar.open(`Offer(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.offerService.reload();
                this.offerpromoService.reload();
                if (this.offerPromoTable) {
                  this.offerPromoTable.clearSelection();
                } else if (this.offerPromoCards) {
                  this.offerPromoCards.clearSelection();
                }
              },
              error: (error) => {
                log.error('Error in deleting Offer/Promo', error);
                this.matSnackBar.open(`Error in deleting Offer/Promo: ${error.message}`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              }
            });
          }
        });
    }
  }

  updateStatus(status: Status) {
    let selectedRecordsDetails: any;
    if (this.offerPromoTable) {
      selectedRecordsDetails = JSON.parse(JSON.stringify(this.offerPromoTable.getSelectedSectionRecords()));
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = JSON.parse(JSON.stringify(this.offerPromoCards.getSelectedSectionRecords()));
    }
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      selectedRecordsDetails = selectedRecordsDetails.map((x: any) => { x.Detail.Status = status; return x.Detail; });
      this.offerpromoService.updateStatus(selectedRecordsDetails).subscribe({
        next: () => {
          this.offerService.reload();
          this.offerpromoService.reload();
          if (this.offerPromoTable) {
            this.offerPromoTable.clearSelection();
          } else if (this.offerPromoCards) {
            this.offerPromoCards.clearSelection();
          }
          this.getStatusCount();
        },
        error: (error) => {
          log.error('Error in updating status', error);
          this.matSnackBar.open(`Error in updating status: ${error.message}`, 'OK', DEFAULT_SNACKBAR_CONFIG);
        }
      });
    }
  }
}
