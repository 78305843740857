<h1>Settings</h1>
<div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" style="margin-bottom: 10px;">
    <mat-accordion fxFlex="45" fxFlexFill fxLayoutAlign="center">
        <mat-expansion-panel style="width: 100%;" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Team Profile
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-add-admin-user></app-add-admin-user>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion fxFlex="45" fxFlexFill fxLayoutAlign="center">
        <mat-expansion-panel style="width: 100%;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Brand Styles
                </mat-panel-title>
            </mat-expansion-panel-header>
            Hello
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" style="margin-bottom: 10px;">
    <mat-accordion fxFlex="45" fxFlexFill fxLayoutAlign="center">
        <mat-expansion-panel style="width: 100%;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Promotion Engine Connections
                </mat-panel-title>
            </mat-expansion-panel-header>
            Hello
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion fxFlex="45" fxFlexFill fxLayoutAlign="center">
        <mat-expansion-panel style="width: 100%;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Channel Subscriptions
                </mat-panel-title>
            </mat-expansion-panel-header>
            Hello
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" style="margin-bottom: 10px;">
    <mat-accordion fxFlex="45" fxFlexFill fxLayoutAlign="center">
        <mat-expansion-panel style="width: 100%;" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Integrations
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-admin-integrations></app-admin-integrations>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion fxFlex="45" fxFlexFill fxLayoutAlign="center">
        <mat-expansion-panel style="width: 100%;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Copy and Global Text
                </mat-panel-title>
            </mat-expansion-panel-header>
            Hello
        </mat-expansion-panel>
    </mat-accordion>
</div>