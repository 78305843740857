<div cdkDropList class="image-list" (cdkDropListDropped)="onElementDrop($event)">
  <div class="image-box" *ngFor="let image of localImageRefs" cdkDrag>
    <mat-card class="image-card">
      <mat-card-content>
        <div class="main">
          <img mat-card-sm-image src="{{ image.image }}" />
          <div class="img-name" style="padding: 5px">
            {{ getImageName(image.image) }}
          </div>
          <div style="">
            <a class="delete-icon" (click)="deleteImage(image)"><fa-icon [icon]="['fas', 'times']"></fa-icon></a>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>