<ng-container *ngIf="viewModel$ | async as viewModel; else loading">

  <div *ngIf="viewModel.user" fxLayout="row" fxLayoutAlign="start center" class="welcome">
    <img src="{{viewModel.user.picture}}" />
    <h1 data-testid="Dashboard Welcome text">Welcome back {{viewModel.user.nickname}}!</h1>
  </div>



  <div class="grid dashbpard-grid">

    <a  appAuthorizeControl="view||navbar"  [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/events']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/events_card_icon.png" />
        <mat-card-content>
          <h2 data-testid="Events">Events</h2>
        </mat-card-content>
      </mat-card>
    </a>
    <a  appAuthorizeControl="admin||navbar"  [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/coupons']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/coupons_card_icon.png" />
        <mat-card-content>
          <h2 data-testid="Events">Coupons</h2>
        </mat-card-content>
      </mat-card>
    </a>

    <a appAuthorizeControl="edit||navbar" [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/assets']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/assets_card_icon.png" />
        <mat-card-content>
          <h2 data-testid="Assets">Assets</h2>
        </mat-card-content>
      </mat-card>
    </a>

    <a  appAuthorizeControl="admin||navbar" [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/products']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/products_card_icon.png" />
        <mat-card-content>
          <h2 data-testid="Products">Products</h2>
        </mat-card-content>
      </mat-card>
    </a>

    <a  appAuthorizeControl="edit||navbar" [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/offertags']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/offer_tags_card_icon.png" />
        <mat-card-content>
          <h2 data-testid="Offer Tags">Offer Tags</h2>
        </mat-card-content>
      </mat-card>
    </a>

    <a appAuthorizeControl="admin||navbar" [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/product-brands']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/brands__card_icon.png" />
        <mat-card-content>
          <h2 data-testid="Brands">Brands</h2>
        </mat-card-content>
      </mat-card>
    </a>

    <a appAuthorizeControl="admin||navbar" [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/product-categories']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/product_categories_card_icon.png" />
        <mat-card-content>
          <h2 data-testid="Product Categories">Categories</h2>
        </mat-card-content>
      </mat-card>
    </a>

    <a appAuthorizeControl="admin||navbar" [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/divisions']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/divisions_card_icon.png" />
        <mat-card-content>
          <h2>Divisions</h2>
        </mat-card-content>
      </mat-card>
    </a>

    <a appAuthorizeControl="admin||navbar" [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/banners']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/banners_card_icon.png" />
        <mat-card-content>
          <h2>Banners</h2>
        </mat-card-content>
      </mat-card>
    </a>

    <a appAuthorizeControl="admin||navbar" [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/stores']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/stores_card_icon.png" />
        <mat-card-content>
          <h2>Stores</h2>
        </mat-card-content>
      </mat-card>
    </a>

    <a appAuthorizeControl="admin||navbar" [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/eventtypes']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/event_types_card_icon.png" />
        <mat-card-content>
          <h2 data-testid="Event Types">Event Types</h2>
        </mat-card-content>
      </mat-card>
    </a>

    <a appAuthorizeControl="admin||navbar" [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/vendors']">
      <mat-card appearance="outlined" class="dashboard-card">
        <img mat-card-image src="./assets/images/vendor.png" />
        <mat-card-content>
          <h2 data-testid="Vendors">Vendors</h2>
        </mat-card-content>
      </mat-card>
    </a>

    <!-- <a  navbar" appAuthorizeControl="view||navbar" [routerLink]="['/' + viewModel.organization?.name?.toLowerCase() + '/digitalads']">
      <mat-card class="dashboard-card">
        <img mat-card-image src="./assets/images/digital_card_img.jpg" />
        <mat-card-content>
          <h2 data-testid="Digital Ads">Digital Ads</h2>
        </mat-card-content>
      </mat-card>
    </a> -->

  </div>

</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
