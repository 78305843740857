
<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
    <form [formGroup]="filtersForm" novalidate>
        <!-- actions bar -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
            <button *ngIf="!readOnlyMode" appAuthorizeControl="edit||offermang" type="button" mat-raised-button
                color="accent" matTooltip="Add new offers" queryParamsHandling="preserve" (click)="openOffersSearchDialog()">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon> Add Offers          
            </button>

            <button type="button"
                *ngIf="(viewModel.viewMode === 'TABLE' && offerPromoTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && offerPromoCards?.getSelectedSectionRecords()?.length > 0) && !readOnlyMode"
                mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
                <mat-icon>more_vert</mat-icon>Actions
            </button>
            <mat-menu #menu="matMenu">
                <button appAuthorizeControl="edit||offermang" type="button" mat-menu-item [matMenuTriggerFor]="Select"><mat-icon>check_circle_outline</mat-icon>Status</button>
                <button type="button" mat-menu-item appAuthorizeControl="edit||offermang"
                    (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
                <button type="button" mat-menu-item
                    *ngIf="(viewModel.viewMode === 'TABLE' && offerPromoTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && offerPromoCards?.getSelectedSectionRecords()?.length > 0)">
                    {{ viewModel.viewMode === 'TABLE' ? offerPromoTable?.getSelectedSectionRecords()?.length :
                    offerPromoCards?.getSelectedSectionRecords()?.length}} Selected
                </button>
            </mat-menu>
            <mat-menu #Select="matMenu">
                <button mat-menu-item (click)="updateStatus(1)">Pending</button>
                <button mat-menu-item (click)="updateStatus(2)">Approve</button>
                <button mat-menu-item (click)="updateStatus(3)">Suppress</button>
            </mat-menu>

            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Search Offers</mat-label>
                <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search"
                    #searchInput />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <span class="menu-spacer"></span>

            <mat-button-toggle-group #group="matButtonToggleGroup" value={{viewModel.viewMode}}>
                <mat-button-toggle value="CARDS" matTooltip="Card view" aria-label="Card view"
                    (click)="toggleView('CARDS')">
                    <mat-icon>grid_view</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="TABLE" matTooltip="Table view" aria-label="Table view"
                    (click)="toggleView('TABLE')">
                    <mat-icon>table_view</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <mat-sidenav-container>           
            <!--table view-->
            <app-offergroup-offers-cards #offerPromoCards *ngIf="viewModel.viewMode === 'CARDS'"
                [readOnly]="readOnlyMode"></app-offergroup-offers-cards>
            <!--expansion view-->
            <app-offergroup-offers-table #offerPromoTable *ngIf="viewModel.viewMode === 'TABLE'" [readOnly]="readOnlyMode"></app-offergroup-offers-table>
        </mat-sidenav-container>
    </form>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>