import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { combineLatest, map } from 'rxjs';
import { Logger, PreviewMode, UtilityService } from 'src/app/@shared';
import { OfferPromo, PromoDomain, OfferPromoService } from '../..';
import { Status } from '../../models/offer-promo-status';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommentsComponent } from 'pr1-ui-components';

const log = new Logger('OfferGroupOffersCardsComponent');

@Component({
  selector: 'app-offergroup-offers-cards',
  templateUrl: './offergroup-offers-cards.component.html',
  styleUrls: ['./offergroup-offers-cards.component.scss'],
})
export class OfferGroupOffersCardsComponent<
  TOfferPromo extends OfferPromo,
  TPromoDomain extends PromoDomain<OfferPromo>
> implements OnInit {
  form = new FormGroup({
    versions: new FormArray([]),
  });
  offersList: any;
  viewModel$ = combineLatest([
    this.offerpromoService.getofferGrpOffers$,
    this.offerpromoService.isLoading$,
    this.offerpromoService.offerGrptotalRecords$,
    this.offerpromoService.offerGrpPage$,
  ]).pipe(
    map(([offers, isLoading, totalRecords, page]) => {
      if (offers.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.offerpromoService.offerGrpPage(page);
        this.offerpromoService.offerGrpReload();
      }
      this.form = new FormGroup({
        versions: new FormArray([]),
      });
      offers.map((x) => this.addVersion(x));
      return { offers, isLoading, totalRecords, page };
    })
  );
  offerpromosList: TPromoDomain[] = [];
  selection = new SelectionModel<TPromoDomain>(true, []);
  versionsList: any[] = [];
  PreviewMode = PreviewMode;
  readOnlyMode = false;
  isCommentDialogOpened: boolean = false;
  @Input() set readOnly(val: boolean) {
    this.readOnlyMode = val;
    this.offerpromoService.setOfferDetailsReadOnly(val);
  }

  constructor(
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    public utilityService: UtilityService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.offerpromoService.commentDialogBoxStatus$.subscribe((status: boolean) => { this.isCommentDialogOpened = status });
    this.offerpromoService.getofferGrpOffers$.subscribe((data) => {
      this.offerpromosList = data;
    });
  }

  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = './assets/images/default-image.png'; // Path to your default image
  }

  onSort(sortState: Sort): void {
    this.offerpromoService.offerGrpSort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.offerpromoService.offerGrpPage(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.offerpromosList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.offerpromosList.forEach((row) => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  updateStatus(status: Status) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = JSON.parse(
      JSON.stringify(this.getSelectedSectionRecords())
    );
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateTableStatus(status: Status, row: any) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = [JSON.parse(JSON.stringify(row))];
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      selectedRecordsDetails = selectedRecordsDetails.map((x: any) => {
        x.Status = status;
        return x;
      });
      this.offerpromoService.updateStatus(selectedRecordsDetails).subscribe({
        next: () => {
          this.offerpromoService.offerGrpReload();
          this.clearSelection();
          this.offerpromoService.updateRecordStatus(new Date());
        },
      });
    }
  }

  canEdit(): boolean { 
    return !this.readOnlyMode;
  }

  getSelectedVersionDetail(event: any, offer: any) {
    offer.activeItem = false;
    offer.Variants = offer.Variants.map((x: any) => {
      x.activeItem = false;
      return x;
    });
    if (event.value && offer) {
      if (event.value.Id === offer.DomainId) {
        offer.activeItem = true;
        return;
      }
      const offerVersion = offer.Variants.find(
        (x: any) => x.Id === event.value.Id
      );
      if (offerVersion) {
        offerVersion.activeItem = true;
      }
    }
  }

  getActiveOffer(offer: any) {
    if (offer.activeItem) {
      return offer.Variants[0];
    }
    const versionOffer = offer.Variants.find(
      (x: any) => x.activeItem === true
    );
    if (!versionOffer) {
      return offer.Variants[0];
    }
    return versionOffer;
  }

  get Variants(): FormArray {
    return this.form.get('versions') as FormArray;
  }
  addVersion(offer: any) {
    if (this.Variants) {
      this.Variants.push(
        new FormControl(
          offer.Variants && offer.Variants.length > 0
            ? offer.Variants[0]
            : null
        )
      );
    }
  }
  openDialog($event: any, offer: any) {
    if (this.isCommentDialogOpened == false) {
      let commentElementStyle = document.getElementById(offer.DomainId) as HTMLElement;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true ;
      dialogConfig.data = {
        headline: offer.Variants[0].Headline,
        channel: offer.EventOfferType,
        parentId: offer.Variants[0].Id,
        clientKey:offer.Variants[0].ClientKey,
        bodyCopy:offer.Variants[0].BodyCopy,
      };
      let confirmDialog = this.dialog.open(CommentsComponent, dialogConfig);
      confirmDialog.afterOpened().subscribe(() => {
        if (commentElementStyle) {
          commentElementStyle.style.color = "blue";
          this.offerpromoService.commentDialogBoxStatusBehaviorSubject.next(true);
        }
      });
      confirmDialog.afterClosed().subscribe((data) => {
        if (data > 0) {
          Object(offer).CommentsCount = data;
        }
        if (commentElementStyle) {
          commentElementStyle.style.color = "black";
          this.offerpromoService.commentDialogBoxStatusBehaviorSubject.next(false);
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.offerpromoService.resetOfferGrpPage();
   }
}
