import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { Pr1UiComponentsModule, AppSwitcherModule, OrgDialogModule, HeaderModule, CommentsModule } from 'pr1-ui-components';

import { ORGANIZATIONS, SharedModule } from 'src/app/@shared';
import { ShellComponent } from '.';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    ShellComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    BreadcrumbModule,
    AppSwitcherModule,
    OrgDialogModule,
    HeaderModule,
    CommentsModule,
    Pr1UiComponentsModule.forRoot(environment, ORGANIZATIONS),
  ]
})

export class ShellModule { }
