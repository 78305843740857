import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger, OrganizationService } from 'src/app/@shared';
import { EventService, Offer, Event, OfferDomain, OfferGroupVersionsComponent, OfferGroupTagsComponent, OfferTag, } from '../..';
import { OfferService, Version } from 'src/app/modules/standard/v1';
import { Subscription, combineLatest, distinctUntilChanged, map, startWith, take } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
const log = new Logger('OfferGroupComponent');

@Component({
  selector: 'app-offer-group',
  templateUrl: './offer-group.component.html',
  styleUrls: ['./offer-group.component.scss'],
})
export class OfferGroupComponent implements OnInit, OnDestroy {
  offersListData: MatTableDataSource<OfferDomain<Offer>> = new MatTableDataSource();
  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'Image', 'Headline', 'BodyCopy', 'Rank'];
  eventId: string = '0';
  event!: Event;
  assetsUrl: string = '';
  selectedVersions: Version[] = [];
  selectedOfferTags: OfferTag[] = [];
  search = new FormControl<string>('');
  offersList: OfferDomain<Offer>[] = [];
  offerGroupOffers: OfferDomain<Offer>[] = [];
  selection = new SelectionModel<OfferDomain<Offer>>(true, [], true, (offer1, offer2) => offer1.Detail.Id === offer2.Detail.Id);
  isSelectionChanged: boolean = false;
  formGroup = new FormGroup({
    version: new FormControl<Version[]>([]),
    offerTags: new FormControl<OfferTag[]>([], { nonNullable: true }),
  });
  viewModel$ = combineLatest([
    this.offerService.offerGroupOffers$,
    this.offerService.offGroupOfferstotalRecords$,
    this.offerService.isLoading$,
    this.offerService.offerGroupPage$,
    this.offerService.viewMode$,
  ]).pipe(
    map(([offers, totalRecords, isLoading, page, viewMode]) => {
      if (offers.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.offerService.page(page);
        this.offerService.reload();
      }
      this.offersListData = new MatTableDataSource(offers);
      return { offers, totalRecords, isLoading, page, viewMode };
    })
  );
  constructor(
    public route: ActivatedRoute,
    public offerService: OfferService<Offer, OfferDomain<Offer>>,
    private mediaObserver: MediaObserver,
    private router: Router,
    private eventService: EventService<Event>,
    private dialog: MatDialog,
    private organizationService: OrganizationService,
    private matSnackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.toggleView('TABLE');
    this.route.params.subscribe(params => {
      this.eventId = params['eventId'];
      this.offerService.eventId = params['eventId'];
    });
    this.eventService.getEvent(this.route.snapshot.params['eventId']).subscribe((res) => {
      this.event = res;
    });
    this.organizationService.assetsUrl$.subscribe((res) => {
      this.assetsUrl = res;
    })
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'Headline'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'Headline'];
        }
        else {
          this.displayedColumns = ['select', 'Image', 'Headline', 'BodyCopy', 'Rank'];
        }
      });

    this.selection.changed.subscribe(() => this.updateisSelectionChanged());
  }

  updateisSelectionChanged() {
    var selectedOfferIds = this.selection.selected.map(offer => offer.Detail.Id);
    var selectedofferGroupOfferIds = this.offerGroupOffers.map(groupOffer => groupOffer.Detail.Id);
    this.isSelectionChanged = selectedOfferIds.length ? !this.areOfferIdsEqual(selectedOfferIds, selectedofferGroupOfferIds) : false
  }

  areOfferIdsEqual(selectedOfferIds: string[], offerGroupOfferIds: string[]): boolean {
    if (selectedOfferIds.length !== offerGroupOfferIds.length) {
      return false;
    }

    const sortedSelectedIds = [...selectedOfferIds].sort();
    const sortedAssetsIds = [...offerGroupOfferIds].sort();

    return sortedSelectedIds.every((id, index) => id === sortedAssetsIds[index]);
  }

  toggleView(mode: string) {
    this.offerService.toggleViewMode(mode);
  }

  toggleValidators(control: AbstractControl, condition: boolean, validators = [Validators.required]) {
    if (condition) {
      control.setValidators(validators);
    } else {
      control.clearValidators();
    }
    control.updateValueAndValidity();
  }

  openVersionsDialog(): void {
    this.toggleValidators(this.formGroup.controls.version, false);
    const dialogRef = this.dialog.open(OfferGroupVersionsComponent, {
      width: '60%',
      height: '90%',
      data: { event: this.eventId, versions: this.selectedVersions }
    });
    dialogRef.afterClosed().subscribe(res => {
      this.toggleValidators(this.formGroup.controls.version, true);
      if (Array.isArray(res)) {
        this.selectedVersions = res;
        this.formGroup.controls.version.patchValue(this.selectedVersions.map((x: any) => x.VersionName));
      }
      this.offerGroupSearch(this.selectedVersions, this.selectedOfferTags, this.search.value);
    });
  }

  openOfferTagsDialog() {
    const dialogRef = this.dialog.open(OfferGroupTagsComponent, {
      width: '60%',
      height: '90%',
      data: { event: this.eventId, offerTags: this.selectedOfferTags }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (Array.isArray(res)) {
        this.selectedOfferTags = res;
        this.formGroup.controls.offerTags.patchValue(this.selectedOfferTags.map((x: any) => x.OfferTagName));
      }
      this.offerGroupSearch(this.selectedVersions, this.selectedOfferTags, this.search.value);
    });
  }

  onSearch(event: any) {
    let search = event.target.value;
    this.offerGroupSearch(this.selectedVersions, this.selectedOfferTags, search);
  }

  clearSearch() {
    this.search.setValue('');
    this.offerGroupSearch(this.selectedVersions, this.selectedOfferTags, '');
  }

  offerGroupSearch(versions: Version[], tags: OfferTag[], searchVal: any) {
    let versionIds = versions.map((x: any) => x.Id);
    let tagIds = tags.map((x: any) => x.Id);
    let search = searchVal ? searchVal : '';
    this.offerService.offerGroupSearch(versionIds, tagIds, search);
  }

  onSort(sortState: Sort): void {
    this.offerService.offerGroupSort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.offerService.offerGroupPage(pageEvent);
  }

  closeDialog() {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  isAllSelected(): boolean {
    return this.offersListData.data.every(offer => this.selection.isSelected(offer));
  }

  toggleSelect(checked: boolean, offer: OfferDomain<Offer>) {
    if (checked) {
      if (!this.selection.isSelected(offer)) {
        this.selection.select(offer);
      }
    } else {
      this.selection.deselect(offer);
    }
  }

  masterToggle(checked: boolean): void {
    if (checked) {
      this.offersListData.data.forEach(offer => {
        if (!this.selection.isSelected(offer)) {
          this.selection.select(offer);
        }
      });
    } else {
      this.offersListData.data.forEach(offer => {
        !this.isOfferSelected(offer) && this.selection.deselect(offer);
      });
    }
  }

  isOfferSelected(offer: OfferDomain<Offer>): boolean {
    return this.offerGroupOffers.some(offerGroupOffer => offerGroupOffer.Detail.Id === offer.Detail.Id);
  }

  isSomeSelectedOnPage(): boolean {
    return this.offersListData.data.some(offer => this.selection.isSelected(offer));
  }

  addToNewOfferGroup() {
    let selectedOffer = this.selection.selected
      .filter(selectedOffer => !this.offerGroupOffers.some(groupOffer => groupOffer.Detail.Id === selectedOffer.Detail.Id))
      .sort((a, b) => a.Detail.Rank - b.Detail.Rank);
    this.offerGroupOffers = [...this.offerGroupOffers, ...selectedOffer];
    this.updateisSelectionChanged();
    this.matSnackBar.open('Offers Added', 'OK', { verticalPosition: 'bottom', panelClass: ['snackbar-error'], duration: 4000 });
  }

  removefromOfferGroup(offer: OfferDomain<Offer>) {
    this.offerGroupOffers = this.offerGroupOffers.filter((offerDomain: OfferDomain<Offer>) => offerDomain.Detail.Id !== offer.Detail.Id);
    this.selection.isSelected(offer) && this.selection.deselect(offer);
  }

  validateOfferGrp(row: OfferDomain<Offer>) {
    if (this.offerGroupOffers.find((offerDomain: OfferDomain<Offer>) => offerDomain.Detail.Id == row.Detail.Id)) {
      return true
    } else {
      return false;
    }
  }

  saveOfferGroup() {
    let offerIds = this.offerGroupOffers.map((x: OfferDomain<Offer>) => x.Detail.Id);
    const dataToSave = {
      EventId: this.eventId,
      OfferTagIds: this.selectedOfferTags.map((offerTag: OfferTag) => offerTag.Id),
      VersionIds: this.selectedVersions.map((version: Version) => version.Id),
      OfferIds: offerIds
    }
    this.offerService.saveOfferGroup(this.eventId, dataToSave).subscribe((res: any) => {
      this.router.navigate([`../${res.Id}/offergroups/offergrpdetails`], { relativeTo: this.route })
    })
  }

  onCancel() {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  onImageError(event: ErrorEvent): void {
    const target = event.target as HTMLImageElement;
    target.src = "./assets/images/default-image.png";
  }

  getImageSrc(asset: OfferDomain<Offer>) {
    let imagesrc = '';
    let type = asset.FacingAsset?.FileType || (asset as any).Type || '';
    let fileName = asset.FacingAsset?.FileName || (asset as any).FileName || '';
    if (type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    } else if (this.assetsUrl) {
      imagesrc = this.assetsUrl + '/' + fileName;
    } else {
      imagesrc = './assets/images/default-image.png';
    }
    return imagesrc;
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

  offerGroupOfferDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.offerGroupOffers, event.previousIndex, event.currentIndex);
  }
}
