<form *ngIf="integration$ | async as T" [formGroup]="formGroup" novalidate>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h1 mat-dialog-title>IA Automation Settings</h1>
        <button type="button" mat-icon-button (click)="closeDialog()" class="close-button">
            <mat-icon aria-hidden="false" aria-label="Close icon">close</mat-icon>
        </button>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="20px">
        <div fxFlex="100" fxFlex.lt-md="100">
            <div class="intcontrol" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                fxLayoutAlign="start start">
                <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                        <mat-label>Organization Id</mat-label>
                        <input matInput type="text" formControlName="OrganizationName" />
                        <mat-error *ngIf="formGroup.controls.OrganizationName.hasError('required')">
                            Organization Id is required
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls.OrganizationName.hasError('invalid')">
                            {{formGroup.controls.OrganizationName.getError('invalid')}}
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls.OrganizationName.status=='INVALID'">
                            {{formGroup.controls.OrganizationName.errors!['maxlength'] &&
                            'Maximum
                            length can be' +
                            formGroup.controls.OrganizationName.errors!['maxlength']['requiredLength']
                            +
                            '.'}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="intcontrol" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                fxLayoutAlign="start start">
                <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                        <mat-label>Client ID</mat-label>
                        <input matInput type="text" formControlName="ClientId" />
                        <mat-error *ngIf="formGroup.controls.ClientId.hasError('required')">
                            Client ID is required
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls.ClientId.hasError('invalid')">
                            {{formGroup.controls.ClientId.getError('invalid')}}
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls.ClientId.status=='INVALID'">
                            {{formGroup.controls.ClientId.errors!['maxlength'] &&
                            'Maximum
                            length can be' +
                            formGroup.controls.ClientId.errors!['maxlength']['requiredLength']
                            +
                            '.'}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="intcontrol" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                fxLayoutAlign="start start">
                <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                        <mat-label>Client Key</mat-label>
                        <input matInput type="text" formControlName="AIClientKey" />
                        <mat-error *ngIf="formGroup.controls.AIClientKey.hasError('required')">
                            Client Key is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="intcontrol" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                fxLayoutAlign="start start">
                <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                        <mat-label>End Point</mat-label>
                        <input matInput type="text" formControlName="Endpoint" />
                        <mat-error *ngIf="formGroup.controls.Endpoint.hasError('required')">
                            End Point is required
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls.Endpoint.hasError('invalid')">
                            {{formGroup.controls.Endpoint.getError('invalid')}}
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls.Endpoint.status=='INVALID'">
                            {{formGroup.controls.Endpoint.errors!['maxlength'] &&
                            'Maximum
                            length can be' +
                            formGroup.controls.Endpoint.errors!['maxlength']['requiredLength']
                            +
                            '.'}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="intcontrol" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                fxLayoutAlign="start start">
                <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                        <mat-label>Template Id</mat-label>
                        <input matInput type="text" formControlName="TemplateId" />
                        <mat-error *ngIf="formGroup.controls.TemplateId.hasError('required')">
                            Template Id is required
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls.TemplateId.hasError('invalid')">
                            {{formGroup.controls.TemplateId.getError('invalid')}}
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls.TemplateId.status=='INVALID'">
                            {{formGroup.controls.TemplateId.errors!['maxlength'] &&
                            'Maximum
                            length can be' +
                            formGroup.controls.TemplateId.errors!['maxlength']['requiredLength']
                            +
                            '.'}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <h2 class="intcontrol">Subscriptions</h2>
    <div class="intcontrol" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="20px">
        <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
            <mat-checkbox formControlName="offer" class="example-margin">Offers</mat-checkbox>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
            <mat-checkbox formControlName="digitalAd" class="example-margin">Digital Ad</mat-checkbox>
        </div>
    </div>
    <div class="intcontrol" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="20px">
        <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
            <mat-checkbox formControlName="promotion" class="example-margin">Promotions</mat-checkbox>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100">
            <mat-checkbox formControlName="digitalAdClassic" class="example-margin">Digital Ad
                Classic</mat-checkbox>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end" class="mat-card-actions-details">
        <button mat-stroked-button type="reset" (click)="closeDialog()">
            Cancel
        </button>
        <button mat-raised-button type="button" (click)="save()" [disabled]="formGroup.invalid" color="accent">
            Save
        </button>
    </div>
</form>