import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Logger, ConfirmDialogComponent, MediaPreviewComponent, UtilityService, PreviewMode, OrganizationService } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { Asset, AssetDomain, AssetGroup, AssetTag, AssetTagService, AssetsDomainService, AssetsService, AssetDetails, OfferTag, OfferTagService } from 'src/app/modules/standard/v1';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as dayjs from 'dayjs';

const log = new Logger('AssetsEditDetailsComponent');
const urlRegex = '^https?:\\/\\/((www\\.)?([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\\/[a-zA-Z0-9@:%_\\+.~#?&//= \\-\\(\\)]*)?(\\?[a-zA-Z0-9&=\\-\\+%]*)?)$';
@Component({
  selector: 'app-assets-edit-details',
  templateUrl: './assets-edit-details.component.html',
  styleUrls: ['./assets-edit-details.component.scss'],
})

export class AssetsEditDetailsComponent<Tag extends OfferTag, ATag extends AssetTag, Group extends AssetGroup> implements OnInit, OnDestroy {
  assets$: Observable<any> = of({} as any);
  assetsDetails!: AssetDetails;
  currentAsset: any;
  assetGroups$: any[] = [] as any[];
  tagCtrl = new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(50)]);
  assetOfferTags: Tag[] = [];
  assetOfferTagIds: string[] = [];
  updatedAssetOfferTagIds: string[] = [];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  PreviewMode = PreviewMode;

  formGroup = new FormGroup({
    detail: new FormGroup({
      clientKey: new FormControl<string | null>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      assetName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      fileName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      assetGroup: new FormControl<string>({ value: '', disabled: true }, [Validators.minLength(1), Validators.maxLength(100)]),
      dateAdded: new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(100)]),
      id: new FormControl<string>('0', { nonNullable: true }),
      description: new FormControl<string>(''),
      additionalSpecs: new FormControl<string>(''),
      dateShot: new FormControl<Date | string | null>(null),
      dateCreated: new FormControl<Date | string | null>(null),
      originalFileName: new FormControl<string | null>(''),
      userNameModified: new FormControl<string>(''),
      dateFileModified: new FormControl<Date | string | null>(null),
      colorChannel: new FormControl<string | null>(''),
      fileType: new FormControl<string | null>(''),
      assetType: new FormControl<string | null>(''),
      imageSource: new FormControl<string | null>(''),
      deleteStatus: new FormControl<boolean>(false),
      doNotUse: new FormControl<boolean>(false),
      expiryDate: new FormControl<Date | string | null>(null),
      embargoDate: new FormControl<Date | string | null>(null),
      keywords: this.fb.array([]),
      restrictions: new FormControl<string>(''),
      stockSourceInformation: new FormControl<string>(''),
      webUrl: new FormControl<string>('', [Validators.pattern(urlRegex)]),
      printUrl: new FormControl<string>('', [Validators.pattern(urlRegex)]),
    })
  });
  groupId: string = '0';
  assetId: string = '0';
  assetType: string = '';
  assetGroupName: string = '';
  assetUrl: string = '';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private assetDomainService: AssetsDomainService<AssetDomain>,
    private assetTagService: AssetTagService<ATag, Group>,
    private offerTagService: OfferTagService<Tag>,
    private matSnackBar: MatSnackBar,
    private assetsService: AssetsService<Asset>,
    private organizationService: OrganizationService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public utilityService: UtilityService) { }

  ngOnInit(): void {
    log.debug('init');
    // get the asset id from the parent route
    this.assetId = this.route.snapshot.params['assetId'];
    this.assetTagService.assetGroups$.subscribe({
      next: (data) => {
        this.assetGroups$ = data;
      }
    });
    this.organizationService.assetsUrl$.subscribe((res) => {
      this.assetUrl = res;
    })
    // if we are editing a record, get the record data
    this.getDataById();

  }

  getOfferTagsByIds(tags: string[]) {
    if (tags.length) {
      this.offerTagService
        .GetOfferTagsByIds(tags)
        .subscribe((offerTags: any) => {
          this.assetOfferTags = offerTags;
        });
    }
  }

  getDataById() {
    if (this.assetId && this.assetId !== '0') {
      this.formGroup.controls['detail'].controls['keywords'] =
        this.fb.array([]);
      this.assets$ = this.assetDomainService.getAssetById(this.assetId).pipe(
        tap((asset: any) => {
          this.currentAsset = asset;
          this.assetsDetails = asset.Detail;
          this.assetType = asset.Detail.FileType;
          this.assetGroupName = asset.AssetGroupName;
          this.getOfferTagsByIds(asset.OfferTagIds)
          let _assetOfferTagIds = Array.isArray(asset.OfferTagIds) ? asset.OfferTagIds : [];
          this.assetOfferTagIds = _assetOfferTagIds;
          this.updatedAssetOfferTagIds = _assetOfferTagIds;
          if (asset && asset.Detail && asset.Detail.EmbargoDate && asset.Detail.EmbargoDate == '01/01/0001') {
            asset.Detail.EmbargoDate = '';
          }
          if (asset && asset.Detail && asset.Detail.ExpiryDate && asset.Detail.ExpiryDate == '01/01/0001') {
            asset.Detail.ExpiryDate = '';
          }
          if (asset && asset.Detail && asset.Detail.DateFileModified && asset.Detail.DateFileModified == '01/01/0001') {
            asset.Detail.DateFileModified = '';
          }
          if (asset && asset.Detail && asset.Detail.DateShot && asset.Detail.DateShot == '01/01/0001') {
            asset.Detail.DateShot = '';
          }
          if (asset && asset.Detail && asset.Detail.DateCreated && asset.Detail.DateCreated == '01/01/0001') {
            asset.Detail.DateCreated = '';
          }
          if (asset && asset.Detail && asset.Detail.DateFileModified) {
            asset.Detail.DateFileModified = JSON.parse(JSON.stringify(new Date(asset.Detail.DateFileModified)));
          }
          if (asset && asset.Detail && asset.Detail.DateShot) {
            asset.Detail.DateShot = JSON.parse(JSON.stringify(new Date(asset.Detail.DateShot)));
          }
          if (asset && asset.Detail && asset.Detail.EmbargoDate) {
            asset.Detail.EmbargoDate = JSON.parse(JSON.stringify(new Date(asset.Detail.EmbargoDate)));
          }
          if (asset && asset.Detail && asset.Detail.ExpiryDate) {
            asset.Detail.ExpiryDate = JSON.parse(JSON.stringify(new Date(asset.Detail.ExpiryDate)));;
          }
          if (asset && asset.Detail && asset.Detail.DateCreated) {
            asset.Detail.DateCreated = JSON.parse(JSON.stringify(new Date(asset.Detail.DateCreated)));;
          }
          if (
            asset &&
            asset.Detail &&
            asset.Detail.Keywords &&
            asset.Detail.Keywords.length > 0
          ) {
            for (
              let index = 0;
              index <= asset.Detail.Keywords.length - 1;
              index++
            ) {
              this.addAdditionalText(asset.Detail.Keywords[index]);
            }
          } else {
            this.addAdditionalText('');
          }
          asset = this.toCamel(asset);
          asset.detail.assetGroup = asset?.assetGroupId
          this.formGroup.patchValue(asset);
          this.formGroup.markAllAsTouched();
        })
      );
    }
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }

  openUrl(url: any) {
    if (url) {
      const value = this.validateUrl(url);
      if (value == null) {
        window.open(url, '_blank');
      }

    }
  }

  validateUrl(value: any) {
    let valid = true;
    try {
      if (value) {
        let validUrl = new URL(value);
        if (validUrl.host == "" && validUrl.origin == "null") {
          valid = false;
        }
      }
    } catch {
      valid = false;
    }
    return valid ? null : { invalidUrl: true };
  }


  save() {
    this.trimControlValues(this.formGroup);

    if (this.formGroup.valid) {
      let assets = this.formGroup.getRawValue() as any;
      assets.OfferTagIds = this.updatedAssetOfferTagIds;
      const dateFileModified = this.formatDate(
        this.formGroup.controls.detail.controls.dateFileModified.value
      );
      const dateShot = this.formatDate(
        this.formGroup.controls.detail.controls.dateShot.value
      );
      const embargoDate = this.formatDate(
        this.formGroup.controls.detail.controls.embargoDate.value
      );
      const expiryDate = this.formatDate(
        this.formGroup.controls.detail.controls.expiryDate.value
      );
      const dateCreated = this.formatDate(
        this.formGroup.controls.detail.controls.dateCreated.value
      );

      const additionaltexts = assets.detail.keywords && assets.detail.keywords.length > 0
        ? assets.detail.keywords.filter(
          (i: any) => i.addtionalTextDetail
        ).map((x: any) => x.addtionalTextDetail) : [];
      const dataToPass = {
        //file: assets.detail.file,
        OfferTagIds: assets.OfferTagIds,
        //Type: this.assetType,
        Detail: {
          FileName: assets.detail?.fileName,
          AssetName: assets.detail?.assetName,
          DateAdded: assets.detail?.dateAdded,
          ClientKey: assets.detail?.clientKey,
          //Type: this.assetType,
          Description: assets.detail?.description,
          AdditionalSpecs: assets.detail?.additionalSpecs,
          DateShot: dateShot,
          OriginalFileName: assets.detail?.originalFileName,
          DateCreated: dateCreated,
          UserNameModified: assets.detail?.userNameModified,
          DateFileModified: dateFileModified,
          ColorChannel: assets.detail?.colorChannel,
          FileType: assets.detail?.fileType,
          AssetType: assets.detail?.assetType,
          ImageSource: assets.detail?.imageSource,
          DeleteStatus: assets.detail?.deleteStatus,
          DoNotUse: assets.detail?.doNotUse,
          ExpiryDate: expiryDate,
          EmbargoDate: embargoDate,
          Restrictions: assets.detail?.restrictions,
          StockSourceInformation: assets.detail?.stockSourceInformation,
          WebUrl: assets.detail?.webUrl,
          PrintUrl: assets.detail?.printUrl,
          Id: assets.detail?.id,
          Keywords: additionaltexts,
        },
        AssetGroupId: assets.detail.assetGroup,
        AssetGroupName: this.assetGroupName
      }

      this.assetDomainService.updateAsset(this.assetId, dataToPass).subscribe({
        next: (response: any) => {

          this.matSnackBar.open(
            `${this.formGroup.controls.detail.controls.assetName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.router.navigate([`../${response.Id}`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
          this.getDataById();
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving asset', error);
            this.matSnackBar.open('500 Error saving asset', 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
          }
          if (error.status === 400) {
            const apiValidations: any = error.error;
            if (Array.isArray(apiValidations)) {
              apiValidations.forEach((validation: any) => {
                if (this.formGroup?.get(validation.PropertyName)) {
                  const control = this.formGroup?.get(validation.PropertyName);
                  if (control) {
                    control.markAsTouched();
                    control.setErrors({ invalid: validation.ErrorMessage });
                    this.matSnackBar.open(validation.ErrorMessage, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                  }
                } else {
                  ///TODO: if we have cross field validation then show the validation error at the top of the screen
                  // if we have cross field validation then show the validation error at the top of the screen
                  // push general error messages to array this is displayed in a toast or dialog
                }
              });
            } else {
              this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
            }
          }
        }
      });
    }
  }


  formatDate(date: Date | string | null) {
    if (date) {
      const newDate = dayjs(date).format('MM/DD/YYYY');
      return newDate;
    } else {
      return '';
    }
  }

  public get additionalTexts() {
    return this.formGroup.controls['detail']?.controls[
      'keywords'
    ] as FormArray;
  }

  addAdditionalText(datatoAdd: string) {
    const formtoAdd = this.fb.group({
      addtionalTextDetail: [
        datatoAdd,
        [Validators.minLength(1), Validators.maxLength(200)],
      ],
    });
    this.additionalTexts.push(formtoAdd);
  }

  deleteAddtionalText(index: number) {
    if (this.additionalTexts.controls.length > 1) {
      this.additionalTexts.removeAt(index);
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route });

  }

  deleteRecord() {
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.detail.assetName}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.assetsService.deleteAsset(record?.detail.id).subscribe({
            next: () => {
              this.matSnackBar.open(`${record.detail.assetName} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.assetsService.reload();
              this.router.navigate([`../`], { relativeTo: this.route });
            },
            error: (error) => {
              log.error('Error deleting asset', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            }
          });
        }
      });
  }

  tagSelected(tagId: string): void {
    if (this.updatedAssetOfferTagIds?.length > 0 && this.updatedAssetOfferTagIds.includes(tagId)) {
      return;
    }
    this.updatedAssetOfferTagIds.push(tagId);
  }

  addTag(offerTag: OfferTag): void {
    this.updatedAssetOfferTagIds.push(offerTag.Id);
    this.matSnackBar.open(`${offerTag.OfferTagName} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG);
  }

  removeTag(tagId: string): void {
    this.updatedAssetOfferTagIds = this.updatedAssetOfferTagIds.filter(id => id !== tagId);
  }

  ngOnDestroy() { }

  toCamel(o: any) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
      return o.map((value) => {
        if (typeof value === "object") {
          value = this.toCamel(value)
        }
        return value
      })
    } else {
      newO = {} as any;
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
          value = o[origKey]
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = this.toCamel(value)
          }
          newO[newKey] = value
        }
      }
    }
    return newO
  }

  openPreviewDialog(): void {
    let urlToPass = '';
    let videoCaptionsUrl = '';
    if (this.assetsDetails && this.assetsDetails.FileType?.includes('x-zip') && this.assetsDetails.ZipConfig && this.assetsDetails.ZipType && this.assetsDetails.ZipType.includes('Video')) {
      let zipConfigInfo = JSON.parse(this.assetsDetails.ZipConfig);
      urlToPass = zipConfigInfo.PrimaryFileUrl;
      videoCaptionsUrl = zipConfigInfo.CaptionsFileUrl;
    } else if (this.assetsDetails.FileType?.includes('x-zip') && !this.assetsDetails?.ZipType?.includes('Video')) {
      urlToPass = '../../../../assets/images/mp4.png'
    }
    else {
      urlToPass = this.assetsDetails.WebUrl as string;
    }
    const dialogRef = this.dialog.open(MediaPreviewComponent, {
      //data: { name: this.assetsDetails.AssetName, url: this.assetsDetails.FileName, type: this.assetsDetails.FileType, assetUrl: this.utilityService.getFilePreviewUrl(this.currentAsset, PreviewMode.Full) },
      data: { name: this.assetsDetails.AssetName, url: this.assetsDetails.FileName, type: this.assetsDetails.FileType, assetUrl: urlToPass, videoCaptionsUrl: videoCaptionsUrl },
    });
  }

}
