import { Component } from '@angular/core';
import { Asset, AssetGroup, AssetsListService, AssetsRankDialogComponent, AssetsSearchDialogComponent, AssetsService, AssetTag, AssetTagService, Division, MontageBuilderService, ViewMode } from '../..';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthorizeControlService } from 'pr1-ui-components';
import { PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { combineLatest, distinctUntilChanged, lastValueFrom, map, Subscription, take } from 'rxjs';
import { DEFAULT_SNACKBAR_CONFIG, Filter, PreviewMode, UtilityService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { FormControl, FormGroup } from '@angular/forms';
import * as dayjs from 'dayjs';
const DEFAULT_SEARCH_PLACEHOLDER = 'Search Assets';

@Component({
  selector: 'app-montage-assets-list',
  templateUrl: './montage-assets-list.component.html',
  styleUrls: ['./montage-assets-list.component.scss']
})
export class MontageAssetsListComponent {
  context = 'Offers'; //Products, Events, Offers, Promos, OfferGroups, Brands, Versions, Coupons
  contextId = '';
  PreviewMode = PreviewMode;
  ViewMode = ViewMode;
  defaultViewMode = ViewMode.Card;
  searchPlaceholder: string = DEFAULT_SEARCH_PLACEHOLDER;
  searchQuery: string = '';

  showFirstLastButtons = true;
  pageEvent: PageEvent = new PageEvent;
  pageSizeOptions = [5, 10, 20, 50, 100, 500];


  allIdsByContext: string[] = [];

  selection = new SelectionModel<Asset>(true, [], true, (a1, a2) => a1.Detail.Id === a2.Detail.Id);
  displayedColumns = ['select', 'Image', 'AssetName', 'Type', 'Group', 'DateAdded', 'DateCreated'];
  flexMediaWatcher!: Subscription;
  assets: Asset[] = [];
  assetsGroups: any[] = [];
  viewModel$ = combineLatest([
    this.montageBuilderService.assetsList$,
    this.assetTagService.assetGroups$,
    this.montageBuilderService.isLoading$,
    this.montageBuilderService.totalRecords$,
    this.montageBuilderService.page$
  ]).pipe(
    map(([assets, assetGroups, isLoading, totalRecords, page]) => {
      if (assets.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.montageBuilderService.page(page);
        this.montageBuilderService.reload();
      }

      this.assets = assets;
      return { assets, assetGroups, isLoading, totalRecords, page }
    }),
  );
  filtersForm = new FormGroup({
    fileTypes: new FormControl<any[]>([], { nonNullable: true }),
    assetGroupIds: new FormControl<any[]>([], { nonNullable: true }),
    endDate: new FormControl<string | null>(null),
    search: new FormControl<string | null>(null),
    startDate: new FormControl<string | null>(null),
  });
  assetsGroup: any[] = [];
  selectedAssetsGroup: any[] = [];
  assetsFilters: string[] = [];
  uploadStartDate: Date | null = null;
  uploadEndDate: Date | null = null;
  fileTypes: any[] = [];
  selectedFileTypes: any[] = [];
  divisionFilters: Filter[] = [];
  fileTypeFilters: Filter[] = [];
  constructor(
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private mediaObserver: MediaObserver,
    private authorizationService: AuthorizeControlService,
    public utilityService: UtilityService,
    private assetsListService: AssetsListService,
    private montageBuilderService: MontageBuilderService,
    private dialogRef: MatDialogRef<MontageAssetsListComponent>,
    private assetTagService: AssetTagService<AssetTag, AssetGroup>,
    private assetsService: AssetsService<Asset>,
  ) { }

  ngOnInit() {

    this.assetsService.getRecordsForFilters().subscribe((res) => {
      this.assetsFilters = res as string[];
    })
    this.detectViewportSizeChange();
  }

  ngOnDestroy(): void {
    this.montageBuilderService.resetServiceState();
  }
  getDistinctRecords(list: any) {
    const allAges = list.value;
    const uniqueSet = new Set(allAges)
    return [...uniqueSet]
  }
  reset() {
    this.montageBuilderService.resetServiceState();
    this.filtersForm.reset();
    this.montageBuilderService.clearFilters();
    this.divisionFilters = [];
    this.fileTypeFilters = [];
  }
  apply() {
    this.onDateRangeChange();
    if (this.divisionFilters.length > 0) {
      this.montageBuilderService.addFilters(this.divisionFilters);
    }

    if (this.fileTypeFilters.length > 0) {
      this.montageBuilderService.addFilters(this.fileTypeFilters);
    }
  }
  canEdit(): boolean {
    return this.authorizationService.checkAccess('edit||offermang');
  }
  onDateRangeChange() {
    let startDate = dayjs(this.filtersForm.controls.startDate.value).format();
    let endDate = dayjs(this.filtersForm.controls.endDate.value).format();
    let isbefore = dayjs(startDate).isBefore(endDate);
    let isSame = dayjs(startDate).isSame(endDate);

    if (startDate === 'Invalid Date' && endDate === 'Invalid Date') {
      this.montageBuilderService.dateRange('', '');
      return;
    }

    if (startDate === 'Invalid Date') {
      this.montageBuilderService.dateRange('', endDate);
    } else if (endDate === 'Invalid Date') {
      this.montageBuilderService.dateRange(startDate, '');
    } else if (this.filtersForm.valid) {
      this.montageBuilderService.dateRange(startDate, endDate);
    }
  }
  onFilterChange(event: any) {
    switch (event.source.ngControl.name) {
      case 'assetGroupIds':
        const values: any[] = event.value;
        this.montageBuilderService.removeFilterByFieldName('assetGroupIds');
        values.forEach((value) => {
          const divisionFilter: Filter = {
            displayText: value.AssetGroupName,
            fieldName: 'assetGroupIds',
            value: value.Id,
          };
          this.divisionFilters.push(divisionFilter);
        });
        break;

      case 'fileTypes':
        const valuesFileTypes: any[] = event.value;
        this.montageBuilderService.removeFilterByFieldName('fileTypes');
        valuesFileTypes.forEach((value) => {
          const eventTypeFilter: Filter = {
            displayText: value,
            fieldName: 'fileTypes',
            value: value,
          }
          this.fileTypeFilters.push(eventTypeFilter);
        });
        break;
    }
  }
  detectViewportSizeChange() {
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'Image', 'AssetName'];
        } else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'Image', 'AssetName', 'Type', 'Group', 'DateAdded', 'DateCreated'];
        } else {
          this.displayedColumns = ['select', 'Image', 'AssetName', 'Type', 'Group', 'DateAdded', 'DateCreated'];
        }
      });
  }

  toggleView(mode: ViewMode) {
    this.defaultViewMode = mode;
  }
  cancelPop() {
    this.dialogRef.close([]);
    this.selection.clear();
  }
  selectAssets() {
    let selectedAssets: any[] = [];
    this.assets.forEach(item => {
      if (this.selection.isSelected(item)) {
        selectedAssets.push(item)
      }
    });
    this.dialogRef.close(selectedAssets);
  }

  clearSearch(): void {
    this.searchQuery = '';
    this.onSearch();
  }

  onSearch() {
    this.selection.clear();
    this.montageBuilderService.search(this.searchQuery);
  }



  onSort(sortState: Sort): void {
    this.montageBuilderService.sort(sortState);
    this.selection.clear();
  }

  onPageChange(pageEvent: PageEvent): void {
    this.selection.clear();
    this.montageBuilderService.page(pageEvent);
  }

  toggleSelect(checked: boolean, asset: any) {
    if (checked) {
      if (!this.selection.isSelected(asset)) {
        this.selection.select(asset);
      }
    } else {
      this.selection.deselect(asset);
    }
  }

  toggleSelectAll(checked: boolean): void {
    if (checked) {
      this.assets.forEach(item => {
        if (!this.selection.isSelected(item)) {
          this.selection.select(item);
        }
      });
    } else {
      this.assets.forEach(item => {
        this.selection.deselect(item);
      });
    }
  }

  isAllSelectedOnPage(): boolean {
    return this.assets.every(item => this.selection.isSelected(item));
  }

  isSomeSelectedOnPage(): boolean {
    return this.assets.some(item => this.selection.isSelected(item));
  }

}
