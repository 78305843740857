import { Component, OnInit } from '@angular/core';
import { OfferPromoService } from '../../services/offerpromo.service';
import { OfferPromo } from '../../models/offer-promo.model';
import { PromoDomain } from '../../models/promo-domain.model';
import { SelectionModel } from '@angular/cdk/collections';
import { combineLatest, map } from 'rxjs';
import { Logger, PreviewMode, UtilityService } from 'src/app/@shared';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Status } from '../../models/offer-promo-status';

const log = new Logger('CloneOffersListTableComponent');

@Component({
  selector: 'app-clone-offers-list-table',
  templateUrl: './clone-offers-list-table.component.html',
  styleUrls: ['./clone-offers-list-table.component.scss'],
})
export class CloneOffersListTableComponent<TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>> implements OnInit {
  Status: typeof Status = Status;
  displayedColumns = ['select', 'Preview', 'Headline', 'BodyCopy', 'Status', 'Rank'];
  offerPromosList: MatTableDataSource<TPromoDomain> = new MatTableDataSource();
  PreviewMode = PreviewMode;
  selection = new SelectionModel<TPromoDomain>(true, [], true, (o1, o2) => o1.DomainId === o2.DomainId);

  viewModel$ = combineLatest([
    this.offerPromoService.cloneOfferPromos$,
    this.offerPromoService.cloneOffersPage$,
    this.offerPromoService.cloneOffersTotalRecords$,
  ]).pipe(
    map(([offers, page, totalRecords]) => {
      this.offerPromosList = new MatTableDataSource(offers);
      return { offers, page, totalRecords };
    })
  );

  constructor(
    private offerPromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    public utilityService: UtilityService,
  ) { }

  ngOnInit(): void {
    log.debug('init');
  }

  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = './assets/images/default-image.png'; // Path to your default image
  }

  getSelectedSectionRecords() {
    return this.selection.selected;
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.offerPromoService.cloneOffersPage(pageEvent);
  }

  isAllSelected(): boolean {
    return this.offerPromosList.data.every(offer => this.selection.isSelected(offer));
  }

  toggleSelectAll(checked: boolean): void {
    if (checked) {
      this.offerPromosList.data.forEach(offer => {
        if (!this.selection.isSelected(offer)) {
          this.selection.select(offer);
        }
      });
    } else {
      this.offerPromosList.data.forEach(offer => {
        this.selection.deselect(offer);
      });
    }
  }

  isSomeSelectedOnPage(): boolean {
    return this.offerPromosList.data.some(offer => this.selection.isSelected(offer));
  }

  toggleSelect(checked: boolean, offer: TPromoDomain): void {
    if (checked) {
      if (!this.selection.isSelected(offer)) {
        this.selection.select(offer);
      }
    } else {
      this.selection.deselect(offer);
    }
  }

  getStatusClass(offer: TPromoDomain): string {
    const status = offer?.Detail?.Status;
    return status?.toString() === '0' ? 'statusPENDING' : 'status' + status;
  }

  getStatusText(offer: TPromoDomain): string {
    const status = offer?.Detail?.Status;
    return status?.toString() === '0' ? 'PENDING' : status?.toString() || '';
  }
}
